
import React, { useEffect, useState } from "react";

import backgroundImage from '../../assets/images/backgrounds/you-re-entered-background.png'
import "./campaign-error.scss";
import CampaignFooter from '../../components/CampaignFooter'
import { useStore } from '../../contexts/app.context'


const CampaignErrorPage = () => {
  const { getPageContent } = useStore()
  const [data, setData] = useState(null)

  useEffect(async () => {
    setData(getPageContent('campaign_submission_error'))
  }, [])

  if (!data) return null

    return (
      <>
      <div style={{ backgroundImage: `url(${backgroundImage})`,backgroundPosition:'center'}}>
        <div className="page landing-page">
          <div className="content relative">
            <div className="inner">

              <div className="body center" style={{ marginTop: '50vh' }}>\
                <h1 className="jw-h2">{data.title1.text}</h1>
                <h2 className="jw-h3">{data.title2.text}</h2>
                <p style={{ textAlign: 'center' }}>{data.text}</p>
              </div>

            </div>

          </div>
        </div>
      </div>
      <CampaignFooter />
      </>
  );
};

export default CampaignErrorPage;
