import React from 'react'

const BeIntro = ({ data }) => {
    return (
        <div className="intro">
            <p className="jw-h6">{data.step2.text}</p>
            {data.title4 && <h3 className="jw-h3" style={{ marginTop: '12px' }}>{data.title4.text}</h3>}
            <h1 className="jw-h1" style={{ marginTop: '12px' }}>{data.title1.text}</h1>
            <span>{data.title5 && <span className="jw-h3 text-right middle" style={{ marginTop: '12px', paddingRight:'10px' }}>{data.title5.text}</span>}
            <span className="jw-h1 text-right middle" style={{ marginTop: '12px' }}>{data.title2.text}</span></span>
        </div>
    )
}

export default BeIntro