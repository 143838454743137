import React, { useEffect, useState } from "react";

import routes from "../../../routes/routes";

import JohnnieWalkerLogo from "../../../components/JohnnieWalkerLogo";
import ButtonUI from '../../../components/ButtonUI';

import backgroundImage from '../../../assets/images/backgrounds/your_details.png'

import { useStore } from '../../../contexts/app.context'

const Error = () => { 
  const { getPageContent, history } = useStore()
  const [data, setData] = useState(null)

  useEffect(async () => {
    setData(getPageContent('record_message'))
    window.scrollTo(0, 0);
  }, [])

  const recordAgain = ()=> {
    history.push(routes.record_message_record)
  }
  const typeATextMessage = ()=> {
    history.push(routes.type_message)
  }

  if (!data) return null

  return (
    <div className="record-error-page record-page page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />
          
          <div className="intro">
            <p className="jw-h3" style={{marginTop: '24px'}}>{data.text7}</p>
            <p className="jw-h3" style={{marginTop: '24px'}}>{data.text8}</p>
          </div>
          <div className="body">
            <ul className="requirements-ul" style={{marginTop: '24px'}}>
              <li className="jw-h6">{data.option1}</li>
              <li className="jw-h6">{data.option2}</li>
            </ul>

            <ButtonUI 
              solid={true}
              text={data.button4}
              onClick={recordAgain}
              style={{marginTop: '24px'}}
              id="record_again_submit"
            />
            <ButtonUI 
              text={data.button5}
              onClick={typeATextMessage}
              style={{marginTop: '24px'}}
              id="type_text_submit"
            />
          </div>

        </div>

      </div>
    </div>
  );
};

export default Error;
