import React, { useEffect, useState } from "react";

import routes from "../../routes/routes";
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import SubmitButton from "../../components/SubmitButton";
import { Form } from "antd";

import backgroundImage from '../../assets/images/backgrounds/occassion.jpg'

import { useStore } from '../../contexts/app.context'

const FourOFour = () => { 
  const { getPageContent, history } = useStore()
  const [data, setData] = useState(null)
  
  useEffect(async () => {
    setData(getPageContent('landing_page'))
  }, [])
  
  useEffect(async () => {
    window.dataLayer = window.dataLayer || []; window.dataLayer.push({
      'event':'404',
      'pagePath': '/404'+window.location.pathname+window.location.search, 'is404':'true'
    }); 
  }, [])

  const onFinish = () => {
    history.push(routes.landing);
    return;
  };

  if (!data) return null

    return (
    <div className="page four-o-four-page" style={{ backgroundImage: `url(${backgroundImage})`}}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />
          
          <div className="intro">
            <h1 className="jw-h2">404</h1>
            <h2 className="jw-h3" style={{marginTop: '7px'}}>An error has occured</h2>
          </div>

          <div className="body" style={{marginTop: '24px'}}>
            <p className="jw-p">Go back or head to the home page:</p>

            <Form onFinish={onFinish}>
              <SubmitButton
                solid={true}
                text={"Go Home"}
                style={{marginTop: '30px'}}
                id="terror_page_home_submit"
              />
            </Form>
          </div>

        </div>

      </div>
    </div>
  );
};

export default FourOFour;
