const routes = {
    index: '/',
    landing: '/',
    your_details: '/your-details',
    who_for: '/who-for',
    occasion: '/occasion',

    campaign_welcome: '/campaign-welcome',
    campaign: '/campaign',
    campaign_pending: '/campaign-pending',
    campaign_stop: '/campaign-stop',
    campaign_success: '/campaign-success',
    campaign_max_attempt: '/campaign-max-attempt',
    campaign_error: '/campaign-error',

    record_message: '/record-message',
    record_message_enable_microphone: '/record-message',
    record_message_record: '/record-message/record',
    record_message_review: '/record-message/review',
    record_message_error: '/record-message/error',

    type_message: '/type-message',

    upload_photo: '/upload-photo',
    upload_photo_error: '/upload-photo/error',
    
    message_preview: '/message-preview',

    enter_gift_code: '/message',
    enter_gift_code_error: '/message/error',
    enter_gift_code_preview: '/message/view-message',
    
    view_message: '/view-message',

    published: '/published',

    message_deleted_success: '/message-deleted',
    message_deleted_failed: '/message-deleteFail',

    four_o_four: '/404',
    icon_twentytwo:'/icon22',
};

export default routes;