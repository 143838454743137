import React, { useRef, useState, useEffect } from 'react';
import { getMobileOS } from '../../utils/common';
import { Select } from 'antd';
import { Input } from "antd";
import ValidationIcon from '../ValidationIcon';

const generateDateObject = (array) => {
  const obj = {}
  array.forEach(element => {
    obj[element] = 0
  });
  return obj
}

const DateNativeUI = ({ id, label, required, classNames, ...props }) => {

  let elementRef = useRef(null);
  const array = props.placeholder.split('/');
  const [dateObj, setDateObj] = useState(generateDateObject(array));
  const [dates, setDates] = useState(generateArray(1, 31))

  useEffect(() => {
    let notefiled = Object.values(dateObj).filter(item => !item);
    if (notefiled.length === 0 && elementRef.current) {
      if (typeof props.onChange === 'function') {
        let value = `${dateObj['YYYY']}/${dateObj['MM']}/${dateObj['DD']}`;
        elementRef.current.value = value;
        props.onChange(new Date(value).toISOString());
      }
    }
  }, [JSON.stringify(dateObj)]);

  const handleChange = (value, type) => {
    const temp = { ...dateObj, [type]: value };
    setDateObj(validatedate(temp));
  };

  const validatedate = (temp) => {
    const checkdate = { 1: 31, 2: 28, 3: 31, 4: 30, 5: 31, 6: 30, 7: 31, 8: 31, 9: 30, 10: 31, 11: 30, 12: 31 };
    if (temp['MM'])
      setDates(generateArray(1, checkdate[temp['MM']]))
    if (temp['MM'] && temp['MM'] == 2 && temp['DD'] >= checkdate[temp['MM']] && temp['YYYY'] % 4 === 0) {
      temp = ({ ...temp, ['DD']: 29 })
      setDates(generateArray(1, 29))
    } else if (temp['MM'] && temp['DD'] >= checkdate[temp['MM']]) {
      temp = ({ ...temp, ['DD']: checkdate[temp['MM']] })
    }
    return temp;
  }
  const renderDate = () => {
    return <div className='form-input-ctrl'>
      <Select required={required} style={{ width: '100%' }} placeholder={'DD'} onChange={(value) => handleChange(value, 'DD')} value={dateObj['DD'] || undefined}>
        {dates.map((date) => (
          <Select.Option key={date.key} value={date.key}>{date.value}</Select.Option>
        ))}
      </Select>
    </div>
  }

  const renderMonth = () => {
    const months = generateArray(1, 12)
    return <div className='form-input-ctrl'>
      <Select required={required} style={{ width: '100%' }} placeholder={'MM'} onChange={(value) => handleChange(value, 'MM')} value={dateObj['MM'] || undefined}>
        {months.map((month) => (
          <Select.Option key={month.key} value={month.key}>{month.value}</Select.Option>
        ))}
      </Select>
    </div>
  }

  const Year = () => {
    const years = generateArray(1913, 2008)
    return <div className='form-input-ctrl'>
      <Select required={required} style={{ width: '100%' }} placeholder={'YYYY'} onChange={(value) => handleChange(value, 'YYYY')} value={dateObj['YYYY'] || undefined}>
        {years.reverse().map((year) => (
          <Select.Option key={year.key} value={year.key}>{year.value}</Select.Option>
        ))}
      </Select>
    </div>
  }

  return (
    <>
      {label && (
        <div>
          <label className="form-label regular jw-label" htmlFor={id}>
            {label}&nbsp;{required && '*'}
          </label>
        </div>
      )}
      <div className="form-input-wrapper">
        {getMobileOS() === 'iOS' ? (
          <>
            <div className={`ant-picker form-input jw-label ant-picker-input  align-center ${classNames}`} style={{ overflow: 'hidden' }}>
              {/* <Input readOnly type="text" placeholder={props.placeholder} ref={elementRef} className={`form-input jw-label ${classNames}`} required={required} id={id} style={{ left: 0, border: 'none', paddingLeft: '16px', position: 'absolute', zIndex: 2, width: '75%' }} value={nativDate} /> */}
              <input type="date" required={required} {...props} id={id} style={{ border: 'none', paddingRight: '16px', paddingLeft:0 }} />
            </div>
            <ValidationIcon />
          </>
        ) :
          (
            <>
              <Input type="date" className={`form-input jw-label ${classNames}`} required={required} {...props} id={id} ref={elementRef} style={{ position: 'absolute', visibility: 'hidden' }} />
              <div className='date-drop-wrapper'>
                {renderDate()}
                {renderMonth()}
                {Year()}
              </div>
              <ValidationIcon />
            </>)}
      </div>
    </>
  );
};

export default DateNativeUI;


const generateArray = (start, end) => {
  const array = []
  for (let index = start; index <= end; index++) {
    array.push({ key: index, value: index })
  }
  return array;
}