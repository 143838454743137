import React from "react";
import { BrowserRouter as Router, Route, Switch, useParams } from "react-router-dom";
import { CSSTransition } from 'react-transition-group'

import routes from "./routes/routes";
import "animate.css";
// import 'antd/dist/antd.css';
import 'antd/dist/reset.css';
import "./styles/styles.scss";

// context (app logic and methods)
import { StoreProvider } from "./contexts/app.context"

// pages
import LandingPage from "./pages/1.Landing/landing"
import YourDetails from "./pages/2.YourDetails/yourDetails"
import WhoFor from "./pages/3.WhoFor/whoFor"
import Occasion from "./pages/4.Occasion/occasion"
import RecordMessage from "./pages/5.RecordMessage"
import TypeMessage from "./pages/5.TypeMessage/typeMessage"
import UploadPhoto from "./pages/6.UploadPhoto"
import MessagePreview from "./pages/7.MessagePreview/messagePreview"
import Published from "./pages/8.Published/messagePublished"
import RedeemGiftCode from "./pages/1.RedeemGiftCode/RedeemGiftCode"
import RedeemGiftCodeError from "./pages/1.RedeemGiftCode/RedeemGiftCodeError"
import MessageDeletedSuccess from "./pages/1.MessageDeleted/MessageDeletedSuccess"
import MessageDeletedFailed from "./pages/1.MessageDeleted/MessageDeletedFailed"
import FourOFour from "./pages/FourOFour/FourOFour"
import Campaign from "./pages/10.Campaign/campaign"
import CampaignPending from "./pages/13.CampaignPending/campaignPending"
import CampaignStop from "./pages/11.CampaignStop/campaignStop"
import CampaignSuccess from "./pages/12.CampaignSuccess/campaignSuccess"
import CampaignWelcomePage from "./pages/14.CampaignWelcome/campaignWelcome"
import CampaignMaxAttemptPage from "./pages/16.CampaignMaxAttempt/campaignMaxAttempt"
import CampaignErrorPage from "./pages/15.CampaignError/campaignError"

import IconTwentyTwo from './pages/icon_twentytwo';
import Footer from './components/Footer/footer';

//
const pageRoutes = [
  { path: routes.index, exact: true, Component: LandingPage },
  { path: routes.campaign_welcome, exact: true, Component: CampaignWelcomePage },
  { path: routes.your_details, exact: true, Component: YourDetails },
  { path: routes.who_for, exact: true, Component: WhoFor },
  { path: routes.occasion, exact: true, Component: Occasion },
  { path: routes.type_message, exact: true, Component: TypeMessage },
  { path: routes.message_preview, exact: true, Component: MessagePreview },
  { path: routes.record_message, exact: false, Component: RecordMessage },
  { path: routes.upload_photo, exact: false, Component: UploadPhoto },
  { path: routes.published, exact: true, Component: Published },
  { path: routes.enter_gift_code, exact: true, Component: RedeemGiftCode },
  { path: routes.enter_gift_code_error, exact: true, Component: RedeemGiftCodeError },
  { path: routes.enter_gift_code_preview, exact: true, Component: MessagePreview },
  { path: routes.message_deleted_success, exact: true, Component: MessageDeletedSuccess },
  { path: routes.message_deleted_failed, exact: true, Component: MessageDeletedFailed },
  { path: routes.campaign, exact: true, Component: Campaign },
  { path: routes.campaign_pending, exact: true, Component: CampaignPending },
  { path: routes.campaign_stop, exact: true, Component: CampaignStop },
  { path: routes.campaign_success, exact: true, Component: CampaignSuccess },
  { path: routes.campaign_error, exact: true, Component: CampaignErrorPage },
  { path: routes.campaign_max_attempt, exact: true, Component: CampaignMaxAttemptPage },
  { path: routes.icon_twentytwo, exact:false, Component:IconTwentyTwo },
  { path: '*', exact: false, Component: FourOFour },
]

export default function App() {

  function Main(){
    let { locale } = useParams();
    locale = locale || 'en-GB'
    // uppercase the country part of the locale always (otherwise transcribe breaks)
    if (locale) {
      locale = locale.split('-')
      if (locale[1]) {
        locale = `${locale[0]}-${locale[1].toUpperCase()}`
      }
    }

    return  (
      <Router basename={"/"+ locale}> 
        <StoreProvider locale={locale}>
          <Switch>
            {pageRoutes.map(({ path, exact, Component }) => (
              <Route key={path} exact={exact} path={path}>
                {({ match }) => {
                  return (  
                    <CSSTransition // https://reactcommunity.org/react-transition-group/with-react-router
                      in={match != null}
                      timeout={1700}
                      classNames="page"
                      unmountOnExit
                      onEntering={()=> window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      })}
                    >
                      <div className={`page-transition ${locale.toLowerCase()}`}>
                        <Component />
                      </div>
                    </CSSTransition>
                  )
                }}
              </Route>
            ))}
          </Switch>
        </StoreProvider>
      </Router>
    )
  }

  return (
    <div className="app">
        <Router>
          <Route path="/:locale?" component={Main}  />
        </Router>
        <Footer />
    </div>
  );
}
