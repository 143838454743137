import React, { useEffect, useState } from "react";
import { Form } from "antd";
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import TextField from "../../components/Fields/TextField";
import EmailField from "../../components/Fields/EmailField";
import TelephoneField from "../../components/Fields/TelephoneField";
import CheckboxField from "../../components/Fields/CheckboxField";
import DateField from "../../components/Fields/DateField";
import DateNativeField from "../../components/Fields/DateNativeField";
import SubmitButton from "../../components/SubmitButton";
import backgroundImage from '../../assets/images/backgrounds/your_details.png'
import "./your-details.scss";
import routes from "../../routes/routes";
import { useStore } from '../../contexts/app.context'
import ErrorField from "../../components/Fields/ErrorField";
import { checkMarket } from '../../utils/common'

const YourDetailsPage = () => {
  const { getPageContent, store, history, postDetails, setCustomer } = useStore();
  const [data, setData] = useState(null)
  const [form] = Form.useForm();

  useEffect(async () => {
    // default form values
    // remove null code so it doesnt overwrite telphone fields default
    window.scrollTo(0, 0);
    if (!store.customer.country_code) {
      delete store.customer.country_code
    }
    form.setFieldsValue(store.customer)

    // page text
    setData(getPageContent('your_details'))
  }, [])

  const onFinish = async () => {
    form.setFields([{ name: 'Error', errors: [] }])

    const formValues = form.getFieldsValue()
    const message_token_from_storage = sessionStorage.getItem('message_token')
    const { customer, message, token, Message } = await postDetails({ ...formValues, message_token: message_token_from_storage || '' })

    if (customer) {
      setCustomer({ ...customer, message_token: message.message_token, bearer_token: token })
      history.push(routes.who_for)
    }
    if (Message) {
      form.setFields([{ name: 'Error', errors: [Message] }])
    }
  };

  const getAge = (DOB) => {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  if (!data) return null

  const dateProps = {
    name: "date_of_birth",
    format: data.form.dateofbirth.placeholder,
    placeholder: data.form.dateofbirth.placeholder,
    label: data.form.dateofbirth.lable,
    rules: [{ required: true, message: data.form.dateofbirth.validationMessage.split(',')[0] }, {
      message: data.form.dateofbirth.validationMessage.split(',')[1],
      validator: (_, value) => {
        if (value) {
          if (getAge(value) >= 18) {
            return Promise.resolve();
          } else {
            return Promise.reject();
          }
        } else
          return Promise.resolve();
      }
    }]
  }

  return (
    <div className="your-details-page page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />

          <div className="intro">
            <h2 className="jw-h3">{data.title1.text}</h2>
          </div>

          <div className="body">
            <Form className="form" form={form} onFinish={onFinish}>
              <TextField
                name="first_name"
                placeholder={data.form.firstname.placeholder}
                label={data.form.firstname.lable}
                rules={[{ required: true, message: data.form.firstname.validationMessage }]}
              />

              <TextField
                name="surname"
                placeholder={data.form.surname.placeholder}
                label={data.form.surname.lable}
                rules={[{ required: true, message: data.form.surname.validationMessage }]}
              />
              {checkMarket(['ja-jp', 'de-de', 'nl-nl', 'fr-be', 'nl-be'], store.customer.locale.toLowerCase()) ? <DateNativeField {...dateProps} /> :
                <>
                  <DateField {...dateProps} />
                  <TelephoneField
                    telephone_name="phone_number"
                    code_name="country_code"
                    locale={store.customer.locale}
                    placeholder={data.form.phonenumber.placeholder}
                    label={data.form.phonenumber.lable}
                  // rules={[{required: true, message: data.form.phonenumber.validationMessage}]}
                  />
                </>
              }

              <EmailField
                name="email"
                label={data.form.email.lable}
                placeholder={data.form.email.placeholder}
                rules={[{ required: true, message: data.form.email.validationMessage }]}
              />

              <ErrorField name="Error" />

              <p className="checkbox-intro-p jw-serif jw-p">{data.title2}</p>

              <CheckboxField
                name="accept_terms"
                label={data.form.checkbox1.lable}
                required={true}
              />
              {data.form.checkbox2.lable && <CheckboxField
                name="contact_email"
                label={data.form.checkbox2.lable}
              />
              }
              {data.form.checkbox3.lable && < CheckboxField
                name="contact_phone"
                label={data.form.checkbox3.lable}
              />}

              <SubmitButton
                solid={true}
                text={data.button}
                id="your_detail_submit"
              />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourDetailsPage;
