import React, { useContext, useEffect, useState } from 'react';
import Loader from '../components/Loader';
import JWFetch from './fetch.js'
import { useHistory } from "react-router-dom";
import routes from "../routes/routes";

const StoreContext = React.createContext();

// static data
let occasions = [];
let pageContents = {};

export function StoreProvider({ children, locale }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [storeReady, setStoreReady] = useState(false);

  const history = useHistory();
  const [state, setState] = useState({
    customer: {
      "locale": locale, // default set in app.js, also updated in onload useEffect here
      "message_token": '',
      "bearer_token": '',
      "first_name": "",
      "surname": "",
      "email": "",
      "phone_number": "",
      "country_code": "",
      "accept_terms": false,
      "contact_email": false,
      "contact_phone": false,
      "occassion_id": null,
      "occassion": null, // birthday
      "occassion_text": "", // happy birthday
      "gifter_name": '',
      "recipient_name": '',
      "text_message": "",
      "audio_file": null,
      "profile_image": null,
      "authoring_complete": false,
      "status": null,
      "passcode": "",
      "url": "",
      "whatsAppUrl": ""
    },
    recording: {
      "url": "",
      "buffer": [], // array buffer
      "file": null,
      "stream": null, // microphone stream
      "instance": null,
      "transcription": null
    },
    photo: {
      buffer: [],
      blob: null,
      base64: null,
    },
  })

  // on load:
  useEffect(async () => {
    const message_token_from_storage = sessionStorage.getItem('message_token')
    const locale_from_storage = state.customer.locale || localStorage.getItem('JMF_LOCALE') // read locale from url or session storage: en-GB

    if (message_token_from_storage) {
      const customer = await getMessage({ 'message_token': message_token_from_storage })
      // only store the message if the authoring is incomplete, otherwise delete the token

      if (!customer.authoring_complete) {
        setCustomer({ ...customer, locale: customer.locale || locale })
      } else {
        sessionStorage.removeItem('message_token')
      }
    }

    await fetchPageContent(locale_from_storage);

    setStoreReady(true)
  }, [])


  // route guards:
  useEffect(async () => {
    if (!storeReady) return;

    const hasToken = state.customer.message_token;
    const isGiftCodePage = history.location.pathname.includes(routes.enter_gift_code);
    const isYourDetailsPage = history.location.pathname.includes(routes.your_details);
    const isGiftCodeMessagePage = history.location.pathname.includes(routes.enter_gift_code_preview);
    const isRecordPage = history.location.pathname.includes(routes.record_message_record);
    const isPublishedPage = history.location.pathname.includes(routes.published);
    const iconTwentyTwo = history.location.pathname.includes(routes.icon_twentytwo);

    //campaign Pages
    const isCampaignWelcome = history.location.pathname.includes(routes.campaign_welcome);
    const iscampaign = history.location.pathname.includes(routes.campaign);
    const isCampaignPending = history.location.pathname.includes(routes.campaign_pending);
    const isCampaignStop = history.location.pathname.includes(routes.campaign_stop);
    const isCampaignSuccess = history.location.pathname.includes(routes.campaign_success);
    const isCampaignmaxAttempt = history.location.pathname.includes(routes.campaign_max_attempt);
    const isCampaignError = history.location.pathname.includes(routes.campaign_error);

    if (isGiftCodePage || isCampaignWelcome || iscampaign || iconTwentyTwo) {
      return;
    }

    if (isCampaignPending || isCampaignStop || isCampaignSuccess || isCampaignmaxAttempt || isCampaignError) {
      console.warn(
        "context route gaurd: Connot access directly"
      );
      history.replace(routes.campaign_welcome);
      return;
    }

    if (!hasToken && !isYourDetailsPage) {
      console.warn(
        "context route gaurd: rerouting because message_token not present."
      );
      history.replace(routes.landing);
      return;
    }

    if (isPublishedPage) {
      if (!state.customer.giftcode) {
        console.warn(
          "context route gaurd: rerouting because no giftcode available"
        );
        history.replace(routes.landing);
      }
    }

    if (isRecordPage && !state.recording.stream) {
      console.warn(
        "context route gaurd: rerouting because no stream available"
      );
      history.replace(routes.record_message_enable_microphone);
      return;
    }

    if (isGiftCodeMessagePage && !state.customer.gifter_name) {
      history.replace(routes.enter_gift_code);
      console.warn(
        "context route gaurd: rerouting because gifter_name not present."
      );
      return;
    }
  }, [storeReady]);

  // watch token and write to sessionStorage on change
  useEffect(async () => {
    if (state.customer.message_token) {
      sessionStorage.setItem('message_token', state.customer.message_token)
    }
  }, [state.customer.message_token]);

  // generic fetch that shows loader when requests are made
  async function Fetch(url, method, body) {
    setLoading(true)
    const res = await JWFetch(url, method, body, state.customer.bearer_token)
    setLoading(false)
    return res;
  }

  function withCommonData(data) {
    return {
      message_token: state.customer.message_token,
      locale: state.customer.locale,
      culture: state.customer.locale,
      ...data,
    };
  }

  function withFileData(data) {
    return {
      locale: state.customer.locale,
      culture: state.customer.locale,
      ...data,
    };
  }

  /* METHODS */

  /* getters */
  const getPageContent = (pageKey) => pageContents[pageKey]

  const fetchPageContent = async (locale = state.customer.locale) => {
    const data = await Fetch(`translation/load/${locale}`, 'GET')
    if (!data.page_contents || Object.keys(data.page_contents).length === 0) {
      alert(`Cannot fetch content for language ${locale}`)
      return;
    }
    occasions = data.occasions;
    pageContents = data.page_contents;

    //setToken(data.token);
  }

  const getMessage = async ({ gift_code, message_token }) => {
    return await Fetch(`author/preview`, 'POST', {
      "gift_code": gift_code,
      "message_token": message_token
    })
  }

  const getRecording = () => state.recording


  /* setters */
  //const setToken = (token) => setCustomer({token});
  const setCustomer = (customer) => setState((value) => ({ ...value, customer: { ...value.customer, ...customer } }))
  const setWhoFor = (whoFor, whoFrom) => setCustomer({ recipient_name: whoFor, gifter_name: whoFrom })
  const setOccasion = (occassion_id, occassion, occassion_text) => setCustomer({ occassion_id, occassion, occassion_text })
  const setTextMessage = (text_message) => setCustomer({ text_message })
  const setPhoto = async ({ blob, buffer, base64 }) => setState((value) => ({ ...value, photo: { blob, buffer, base64 } }))
  const setRecording = async (recording) => setState({ ...state, recording: { ...state.recording, ...recording } })
  const setMessage = async (customer) => setCustomer(customer)
  const setPublish = async (passcode, whatsAppUrl, url, qrcode) => setCustomer({ passcode, whatsAppUrl, url, qrcode })
  //const setReceipt = async(receipt)=> setState({...state, receipt: {receipt}})


  /* posts */
  const postDetails = async (customer) => await Fetch('author/SubmitDetails', 'POST', withCommonData(customer))
  const postWhoFor = async (whoFor, whoFrom) => await Fetch('message/UpdateGifterInformation', 'POST', withCommonData({
    for: whoFor,
    from: whoFrom
  }))
  const postOccasion = async (occassion_id, other_ocassion) => await Fetch('message/UpdateOccassion', 'POST', withCommonData({
    occassion_id: occassion_id,
    custom_occasion: other_ocassion
  }))
  const postTextMessage = async (text) => await Fetch('author/addText', 'POST', withCommonData({
    text
  }))
  const postPhoto = async (buffer) => await Fetch(`author/addPhoto`, 'POST', withCommonData({
    "image": buffer
  }))
  const postRecording = async (transcript, audio) => await Fetch(`author/addRecording`, 'POST', withCommonData({
    transcript,
    audio
  }))
  const postPublish = async (rating) => await Fetch(`author/finish`, 'POST', withCommonData({ rating }))

  const postReceiptDetails = async (customer) => await Fetch('competition/Submit', 'POST', withFileData(customer))

  const submitSubscribeForm = async(email, receiveOffers) => await Fetch('subscribe/submit', 'POST', withCommonData({
    email: email,
    receiveOffers: receiveOffers
  }))

  /* reset */
  const clearState = () => {
    setLoading(true);
    window.sessionStorage.removeItem("message_token");
    state.customer = {
      locale: state.customer.locale
    };
    state.recording = {};
    state.photo = {};
    setLoading(false);
  };

  /* hold up app until store is ready */
  if (!storeReady) return <Loader show={true} />;

  /* exposed state and methods */
  return (
    <StoreContext.Provider value={
      {
        fetchPageContent,

        postDetails,
        postReceiptDetails,
        setCustomer,

        postWhoFor,
        setWhoFor,

        postOccasion,
        setOccasion,

        postTextMessage,
        setTextMessage,

        postPhoto,
        postRecording,
        getPageContent,

        getRecording,
        setRecording,

        setPhoto,

        postPublish,
        setPublish,

        submitSubscribeForm,

        getMessage,
        setMessage,

        clearState,

        // state
        state,
        store: state,
        setState,
        setLoading,
        error,
        setError,
        occasions,
        storeReady,

        // utils
        history,
      }}
    >
      {children}
      <Loader show={loading} />
    </StoreContext.Provider>
  );
}

export function useStore() {
  return useContext(StoreContext);
}
