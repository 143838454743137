import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import routes from "../../../routes/routes";

const MicRecorder = require("mic-recorder-to-mp3");

import JohnnieWalkerLogo from "../../../components/JohnnieWalkerLogo";
import ButtonUI from "../../../components/ButtonUI";
import backgroundImage from "../../../assets/images/backgrounds/your_details.png";

import StopIcon from "../../../assets/images/icons/stop.svg";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import "./record.scss";

import { useStore } from "../../../contexts/app.context";

const recorder = new MicRecorder({
  bitRate: 128,
});
let interval = null;
let timeout = null;
const maxRecordingTime = 120;

export default function Record() {
  const {
    getPageContent,
    setRecording,
    postRecording,
    setLoading,
    setCustomer,
    history,
  } = useStore();
  const [data, setData] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [recordingMinuteSeconds, setRecordingMinuteSeconds] = useState("0:00");
  const [stop, setStop] = useState(true);

  // on load
  useEffect(async () => {
    window.scrollTo(0, 0);
    setData(getPageContent("record_message"));
  }, []);

  const startRecording = async () => {

    recorder
      .start()
      .then(() => {
        // count recording time and create ui time string: 00:00
        interval = setInterval(() => {
          setRecordingTime((value) => {
            const recorded_seconds = value + 1;
            setRecordingMinuteSeconds(() => {
              const time = recorded_seconds;
              const minutes = Math.floor(time / 60);
              const seconds = minutes < 1 ? time : time - 60 * minutes;
              return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
            });
            return recorded_seconds;
          });
        }, 1000);

        // set timeout to stop after max time reached
        timeout = setTimeout(stopRecording, maxRecordingTime * 1000);

        // show recorder circle
        setStop(false);
      })
      .catch((e) => {
        console.error(e);
        alert("an error occured while starting the stream, view the console for errors");
      });
  };

  // stop and post recording
  const stopRecording = async () => {
    setLoading(true);

    // stop counting
    clearInterval(interval);
    clearTimeout(timeout);

    recorder
      .stop()
      .getMp3()
      .then(async ([buffer, blob]) => {

        const file = new File(buffer, "recording.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });

        const player = new Audio(URL.createObjectURL(file));
        window.document.body.appendChild(player);

        let fileBuffer = await file.arrayBuffer();
        let fileBufferArray = Array.from(new Uint8Array(fileBuffer));

        // post the buffer
        const { has_profanity, filename, Message } = await postRecording(
          fileBufferArray
        );

        // if success, save it to the stores context
        if (!has_profanity) {
          setCustomer({
            audio_file: filename,
          });
          setRecording({
            file: file,
            buffer: fileBufferArray,
          });

          // go to next page
          history.push(routes.record_message_review);
        }

        if (has_profanity) {
          history.push(routes.record_message_error);
        }

        // unforseen error
        if (Message) {
          alert(Message);
        }
      });
  };

  // guards
  if (!data) return null;

  return (
    <div
      className="record-message-page record-page page"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />

          <div className="intro">
            <p className="jw-h6">{data.step4.text}</p>

            <h1 className="jw-h1" style={{ marginTop: "12px" }}>
              <span className="jw-h1">{data.title1.text}&nbsp;</span>
              <span className="jw-h3">{data.title2.text}</span>
              <span className="jw-h2 text-right block" dangerouslySetInnerHTML={{ __html: data.title3.text }}></span>
            </h1>
          </div>

          {/* start recording button */}
          {stop && (
            <div className="body message-recorder">
              <p className="jw-label jw-serif">{data.title6}</p>
              <ButtonUI
                solid={true}
                text={data.button2}
                onClick={startRecording}
                id="recording_message_submit"
              />
              <Link
                to={routes.type_message}
                className="text-center jw-label jw-serif block"
              >
                {data.text2}
              </Link>
            </div>
          )}

          {/* recorder circle */}
          {!stop && (
            <div className="body" style={{ padding: 0 }}>
              <div className="recorder">
                <div className="recorder-circle">
                  <CircularProgressbar
                    value={(recordingTime / maxRecordingTime) * 100}
                    strokeWidth={1}
                  />
                  ;
                  <div className="recorder-circle-content">
                    <div className="recorder-time jw-h6">
                      {recordingMinuteSeconds}
                    </div>
                    <div className="recorder-stop" onClick={stopRecording}>
                      <img src={StopIcon} />
                    </div>
                    <h4 className="recorder-text jw-h6">{data.text3}</h4>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
