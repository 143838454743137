import React from 'react'

const Intro = ({data}) => {
    return (
        <div className="intro">
            <p className="jw-h6">{data.step2.text}</p>
            <h1 className="jw-h1" style={{ marginTop: '12px' }}>{data.title1.text}</h1>
            <h2 className="jw-h1 text-right middle" style={{ marginTop: '7px' }}>{data.title2.text}</h2>
            {data.title3 && <h2 className="jw-h1 text-right" style={{ marginTop: '7px' }}>{data.title3.text}</h2>}
        </div>
    )
}

export default Intro