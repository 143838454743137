import React, { useEffect, useState } from "react";

import routes from "../../routes/routes";
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import SubmitButton from "../../components/SubmitButton";
import { Form } from "antd";

import backgroundImage from '../../assets/images/backgrounds/landing_bg.png'
import "./landing.scss";

import { useStore } from '../../contexts/app.context'
import CheckLocaleForBE from '../../custom/checkLocaleForBe'

const LandingPage = () => {
  const { getPageContent, history, getMessage, setMessage } = useStore()
  const [data, setData] = useState(null)
  const queryParams = new URLSearchParams(window.location.search);
  const message_token = queryParams.get("token");

  useEffect(async () => {
    if (message_token) {
      const MessageTokenData = await getMessage({ message_token })
      sessionStorage.setItem('message_token', message_token)
      if (MessageTokenData.status === '7') {
        setData(getPageContent('landing_page'))
      } else if (MessageTokenData.authoring_complete) {
        const message = await getMessage({ message_token, gift_code: MessageTokenData.passcode })
        if (message.has_expired === false) {
          setMessage(message)
          history.push(routes.message_preview);
        }
      } else {
        history.push(routes.who_for);
      }
    } else
      setData(getPageContent('landing_page'))
  }, [])

  const onFinish = () => {
    history.push(routes.your_details);
    return;
  };

  if (!data) return null

  return (
    <div className="page landing-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />

          <div className="intro">
            <h1 className="jw-h2">{data.title1.text}</h1>
            <h2 className="jw-h3" style={{ marginTop: '7px' }}>{data.title2.text}</h2>
          </div>

          <div className="body">
            <div className="jw-h3" style={{ marginTop: '315px' }} dangerouslySetInnerHTML={{ __html: data.title3.text }}></div>
          </div>

          <Form onFinish={onFinish}>
            <SubmitButton
              solid={true}
              text={data.button}
              style={{ marginTop: '184px' }}
            />
          </Form>
        </div>

      </div>
      <CheckLocaleForBE />
    </div>
  );
};

export default LandingPage;
