import React, { useState, useCallback } from 'react'

import Dropzone from "react-dropzone";
import Cropper from 'react-easy-crop'
import cameraIcon from '../../assets/images/icons/camera.png'
import closeCircleIcon from '../../assets/images/icons/close-circle-white.svg'
import ButtonUI from '../ButtonUI'
import getCroppedImg from './cropImage'

import './image-cropper.scss'

const imageTypes = ['.jpg', '.jpeg', '.png']
const dropzoneAcceptedTypes = {
	'image/*': imageTypes
}

export default function ImageCropper({imageUrl, onChange, onError, text1, text2, btnText}){
  const [imageFile, setImageFile] = useState({
		url: imageUrl,
    base64: null,
    buffer: []
  })

  const onImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const base64 = new FileReader();

    base64.readAsDataURL(file);
    base64.onload = async () => {
      setImageFile({base64: base64.result})
    };   
  }

  const onImageDrop = (files) => {
    onImageChange({target: { files }})
  }

  const onDropRejected = (e) => {
    try {
      const error = e[0].errors[0]

      if (onError) onError(error)
      if (!onError) console.warn('ImageCropper: use onError prop to get the error message', error)
    } catch (e) {
      console.error('ImageCropper: file has been rejected and retrieving the error message failed. onError not triggered.')
    }
  }

  const onImageRemove = () => {
    setImageFile({url: null, base64: null, blob: null, buffer: []})
  }

  // crop image
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [minZoom, setMinZoom] = useState(1)
  const [zoom, setZoom] = useState(1)
  const [aspect]= useState(4/4)
  
  const [width, setWidth]= useState(300)
  const [height, setHeight]= useState(300)

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropChange = (crop) => setCrop(crop)
  const onZoomChange = (zoom) =>  setZoom(zoom)
  const onCropComplete = (croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)
  const onComplete = async () => {
    if (onChange) {
			if (imageFile.base64){
				const { blob, base64 } = await getCroppedImage()
				const fileAsBuffer = await blob.arrayBuffer();
				var buffer = Array.from(new Uint8Array(fileAsBuffer));
				onChange({ blob, base64, buffer })
			} else {
				onChange({ url: imageUrl })
			}
    }
  }

  const getCroppedImage = useCallback(async () => {
    try {
      return await getCroppedImg(
        imageFile.base64,
        croppedAreaPixels
      )
    } catch (e) {
      console.error(e, width, height)
    }
  }, [croppedAreaPixels])

  return (
    <div className={`image-cropper ${imageFile.base64 && 'has-image'}`}>
      <div className="image-cropper-remove-image">
        <button onClick={onImageRemove} type="button" style={{height: '49px', width: '49px'}}>
          <img src={closeCircleIcon} />
        </button>
      </div>
      <div className="crop-container">
        <Dropzone
          onDrop={onImageDrop}
          onDropRejected={onDropRejected}
          multiple={false}
          accept={dropzoneAcceptedTypes}
        >
          {({getRootProps, getInputProps}) => (
            <div className="image-cropper-info-circle" {...getRootProps()}>
              <div className="image-cropper-content">
                <div className="formats">
                  <span className="jw-h6">{text1}</span>
                </div>
                <div className="camera-icon"><img src={cameraIcon} width="40px" /></div>
                <div className="description">
                  <p className="jw-h6">{text2}</p>
                </div>
              </div>
              <div className="image-cropper-input">
                { (imageFile.url || imageFile.base64) && <img src={imageFile.base64 || imageFile.url} />}
                <input type="file" name="logo" onChange={onImageChange} {...getInputProps()} accept={imageTypes.join()} />
              </div>
            </div>
          )}
        </Dropzone>
        <Cropper
          image={imageFile.base64 || imageFile.url}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          cropSize={{width: 300, height: 300}}
          showGrid={false}
          onCropComplete={onCropComplete}
          onCropChange={onCropChange}
          onMediaLoaded={(media) => {
            setWidth(media.width)
            setHeight(media.height)
            
            // if image is portait or landscape we have to zoom so it fits the square
            let zoomLevel = 0;
            if (media.width < media.height) {
              zoomLevel = 300 / media.width
            }else {
              zoomLevel = 300 / media.height
            }
            setMinZoom(zoomLevel)
            onZoomChange(zoomLevel)
          }}
        />
      </div>
      <div className="controls">
        <input
          type="range"
          value={zoom}
          min={minZoom}
          max={minZoom+2}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => onZoomChange(e.target.value)}
          className="zoom-range"
        />
        
        {(imageFile.base64 || imageFile.url) && 
          <ButtonUI 
            solid={true}
            text={btnText}
            onClick={onComplete}
          />
        }
      </div>
    </div>
  )
}