import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import routes from "../../../routes/routes";

import JohnnieWalkerLogo from "../../../components/JohnnieWalkerLogo";
import ButtonUI from '../../../components/ButtonUI';

import backgroundImage from '../../../assets/images/backgrounds/your_details.png'

import './error.scss';

import { useStore } from '../../../contexts/app.context'

const Error = () => { 
  const { getPageContent, history } = useStore()
  const [data, setData] = useState(null)

  useEffect(async () => {
    setData(getPageContent('upload_errors'))
    window.scrollTo(0, 0);
  }, [])

  const uploadAnotherPicture = ()=> {
    history.push(routes.upload_photo)
  }
  const continueWithoutPicture = ()=> {
    history.push(routes.message_preview)
  }

  if (!data) return null

  return (
    <div className="upload-photo-error-page page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />
          
          <div className="intro" style={{marginTop: '36px'}}>
            <h1>
              <div className="jw-h3">{data.title1.text}</div>
            </h1>
            <h2 className="jw-h2" style={{ marginTop: '24px'}}>
              <div className="jw-h3" dangerouslySetInnerHTML={{__html: data.title2.text}}></div>
            </h2>
          </div>

            <div className="body">
              <ol className="list jw-h6">
                <li>{data.option1}</li>
                <li>{data.option2}</li>
              </ol>

              <ButtonUI
                solid={true}
                text={data.button1}
                onClick={uploadAnotherPicture}
                style={{ marginTop: '24px'}}
                id="upload_another_picture_submit"
              />

              <ButtonUI
                solid={false}
                text={data.button2}
                onClick={continueWithoutPicture}
                style={{ marginTop: '24px'}}
                id="continue_without_picture_submit"
              />
                 
              <Link to={routes.type_message} className="text-center jw-label jw-serif block">{data.text2}</Link>
            </div>
        </div>

      </div>
    </div>
  );
};

export default Error;
