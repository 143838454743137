import React from 'react';
import johnnieWalkerLogo from '../../assets/images/brand/logo.svg';
import johnnieWalkerLogoWhite from '../../assets/images/brand/logo-white.svg';

const JohnnieWalkerLogo = ({ black = true, ...props }) => {
  return (
    <div className="johnnie-walker-logo flex flex-column flex-center" {...props}>
      <img src={black ? johnnieWalkerLogo : johnnieWalkerLogoWhite} alt="Johnnie Walker Logo" />
    </div>
  );
};

export default JohnnieWalkerLogo;