import React, { useEffect, useState } from "react";
import './icon_twentytwo.scss';
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import backgroundImage from '../../assets/images/backgrounds/blackmagick_BG.jpg';
import { useStore } from '../../contexts/app.context';
import ButtonUI from "../../components/ButtonUI";

export default function IconTwentyTwo() {

    const { getPageContent } = useStore()
    const [data, setData] = useState(null);

    useEffect(async () => {
        setData(getPageContent('icon22_page'))
    }, [])

    if (!data) return null

    return (
        <div className="page icons-twentytwo" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="content relative">
                <div className="inner">
                    <JohnnieWalkerLogo black={false} />

                    <div className="intro">
                        <h1 className="jw-h2">{data.title1.text}</h1>
                        <h2 className="jw-h3" style={{ marginTop: '7px' }}>{data.title2.text}</h2>
                    </div>

                    <div className="body">
                        <section>
                            <div className="jw-h3" style={{ marginTop: '55px' }} dangerouslySetInnerHTML={{
                                __html: data.title3
                            }}></div>
                            <p className="jw-label jw-serif" dangerouslySetInnerHTML={{ __html: data.description1 }} />
                            <ButtonUI
                                solid={true}
                                text={data.button1.text}
                                style={{ marginTop: '20px' }}
                                onClick={() => window.open(data.button1.url, data.button1.target || '_blank')}
                            />
                        </section>

                        <section>
                            {data.title4 && <div className="jw-h3" style={{ marginTop: '55px' }} dangerouslySetInnerHTML={{ __html: data.title4 }}></div>}
                            {data.description2 && <p className="jw-label jw-serif" dangerouslySetInnerHTML={{ __html: data.description2 }} />}
                            {data.button2 && <ButtonUI
                                solid={true}
                                text={data.button2.text}
                                style={{ marginTop: '20px' }}
                                onClick={() => window.open(data.button2.url, data.button2.target || '_blank')}
                            />}
                        </section>

                        <section>
                            {data.title5 && <div className="jw-h3" style={{ marginTop: '55px' }} dangerouslySetInnerHTML={{ __html: data.title5 }}></div>}
                            {data.description3 && <p className="jw-label jw-serif" dangerouslySetInnerHTML={{ __html: data.description3 }} />}
                            {data.button3 && <ButtonUI
                                solid={true}
                                text={data.button3.text}
                                style={{ marginTop: '20px' }}
                                onClick={() => window.open(data.button3.url, data.button3.target || '_blank')}
                            />}
                        </section>
                        {
                            data.description4 &&
                            <section className="conatct-us">
                                <p className="jw-label jw-serif" dangerouslySetInnerHTML={{ __html: data.description4 }}></p>
                            </section>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
