import React, { useEffect, useState } from "react";
import { Form, Upload } from "antd";
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import TextField from "../../components/Fields/TextField";
import EmailField from "../../components/Fields/EmailField";
import CheckboxField from "../../components/Fields/CheckboxField";
import DateField from "../../components/Fields/DateField";
import SubmitButton from "../../components/SubmitButton";
import backgroundImage from '../../assets/images/backgrounds/your_details.png'
import "./campaign.scss";
import { useStore } from '../../contexts/app.context'
import ErrorField from "../../components/Fields/ErrorField";
import routes from "../../routes/routes";
import CampaignFooter from "../../components/CampaignFooter"

const CampaignPage = () => {
  const { getPageContent, postReceiptDetails, history } = useStore();
  const [data, setData] = useState(null)
  const [dates, setDates] = useState(null)
  const [form] = Form.useForm();

  useEffect(async () => {
    setData(getPageContent('campaign_receipt'))
  }, [])

  useEffect(async () => {
    setDates(getPageContent('campaign_dates'))
    let endDate = new Date(dates?.end_date);
    let startDate = new Date(dates?.start_date);
    let today = Date.now();

    if (endDate || startDate) {
      if (today < startDate) {
        history.push(routes.campaign_pending)
      }
      if (today > endDate) {
        history.push(routes.campaign_stop)
      }
    }

  }, [dates])

  const onFinish = async () => {
    form.setFields([{ name: 'Error', errors: [] }])

    const formValues = form.getFieldsValue()

    const res = await postReceiptDetails(formValues)
    if (res.Success === true) {
      history.push(routes.campaign_success)
    }
    if (res.Success === false) {
      if (res.status == 1) {
        history.push(routes.campaign_maximum_attempt_reached)
      }
      if (res.status == 2) {
        history.push(routes.campaign_submission_error)
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getAge = (DOB) => {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  if (!data) return null

  return (
    <>
    <div className="your-details-page page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />

            <div className="intro">
              <h2 className="jw-h3">Entry Form</h2>
            </div>

            <div className="body">
              <Form className="form" form={form} onFinish={onFinish}>
                <TextField
                  name="First_Name"
                  placeholder={data.form.firstname.placeholder}
                  label={data.form.firstname.lable}
                  rules={[{ required: true, message: data.form.firstname.validationMessage }]}
                />

                <TextField
                  name="Surname"
                  placeholder={data.form.surname.placeholder}
                  label={data.form.surname.lable}
                  rules={[{ required: true, message: data.form.surname.validationMessage }]}
                />
                <DateField
                  name="Date_Of_Birth"
                  placeholder={data.form.dateofbirth.placeholder}
                  label={data.form.dateofbirth.lable}
                  rules={[{ required: true, message: data.form.dateofbirth.validationMessage.split(',')[0] }, {
                    message: data.form.dateofbirth.validationMessage.split(',')[1],
                    validator: (_, value) => {
                      if (value) {
                        if (getAge(value) >= 18) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject();
                        }
                      } else
                        return Promise.resolve();
                    }
                  }]}
                />

                <EmailField
                  name="Email"
                  label={data.form.email.lable}
                  placeholder={data.form.email.placeholder}
                  rules={[
                    {
                      required: true,
                      message: data.form.email.validationMessage
                    }
                  ]}
                />

                <EmailField
                  name="confirmemail"
                  label={data.form.confirmemail.lable}
                  placeholder={data.form.confirmemail.placeholder}
                  dependencies={['Email']}
                  rules={[
                    {
                      required: true,
                      message: data.form.confirmemail.validationMessage,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('Email') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(data.form.mailconformationerror.validationMessage));
                      },
                    }),
                  ]}
                />

                <ErrorField name="Error" />

                <p className="checkbox-intro-p jw-serif jw-p">{data.title2}</p>

                <CheckboxField
                  name="Is_contact_by_jw"
                  label={data.form.checkbox1.lable}
                  rules={[{ required: data.form.checkbox1.validationMessage ? true : false, message: data.form.checkbox1.validationMessage }]}
                />
                <CheckboxField
                  name="accept_to_capture_details"
                  label={data.form.checkbox2.lable}
                  rules={[{ required: data.form.checkbox2.validationMessage ? true : false, message: data.form.checkbox2.validationMessage }]}
                />
                <CheckboxField
                  name="Is_contact_by_diageo"
                  label={data.form.checkbox3.lable}
                  rules={[{ required: data.form.checkbox3.validationMessage ? true : false, message: data.form.checkbox3.validationMessage }]}
                />
                <CheckboxField
                  name="terms_condition"
                  label={data.form.checkbox4.lable}
                  rules={[{ required: data.form.checkbox4.validationMessage ? true : false, message: data.form.checkbox4.validationMessage }]}
                />

                <Form.Item>
                  <Form.Item
                    name="receipt"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: data.form.fileupload.validationMessage,
                      },
                    ]}
                  >
                    <Upload.Dragger
                      listType="picture"
                      accept="image/*"
                      maxCount="1"
                      multiple={false}
                      beforeUpload={() => false}
                    >
                      <p className="ant-upload-text">
                        {data.button1}
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>

              <SubmitButton
                solid={true}
                text={data.button2}
                id="your_detail_submit"
              />
            </Form>
          </div>
        </div>
      </div>
    </div>
    <CampaignFooter />
    </>
  );
};

export default CampaignPage;
