import React from 'react';
import SuccessIcon from '../../assets/images/check-success-green.svg';
import ErrorIcon from '../../assets/images/cross-error-red.svg';

const ValidationIcon = () => (
  <>
    <span className="validation-icon validation-success flex flex-column align-center justify-center">
      <img src={SuccessIcon} className="validation-icon-img" />
    </span>
    <span className="validation-icon validation-error flex flex-column align-center justify-center">
      <img src={ErrorIcon} className="validation-icon-img" />
    </span>
  </>
);

export default ValidationIcon;
