export const checkIsGermen = (locale) => {
    return locale.toLowerCase() === 'nl-nl' ||
        locale.toLowerCase() === 'de-de' ||
        locale.toLowerCase() === 'fr-be' ||
        locale.toLowerCase() === 'nl-be'
}

export const showWhatupUrlMarket = (locale) => {
    return locale.toLowerCase() === 'nl-nl' ||
        locale.toLowerCase() === 'de-de'
}

export const checkfeedback = (locale) => {
    return locale.toLowerCase() === 'nl-nl' ||
        locale.toLowerCase() === 'de-de'
}

export const checkSubscriptions = (locale) => {
    return locale.toLowerCase() === 'nl-nl'||
    locale.toLowerCase() === 'de-de'
}

// export const isTouchDevice = () => {
//     return (('ontouchstart' in window) ||
//         (navigator.maxTouchPoints > 0) ||
//         (navigator.msMaxTouchPoints > 0));
// }

export const getMobileOS = () => {
    // console.log('navigator', navigator)
    // const ua = navigator.userAgent
    // if (/android/i.test(ua)) {
    //     return "Android"
    // }
    // else if ((/iPad|iPhone|iPod/.test(ua))
    //     || (navigator.userAgentData.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    //     return "iOS"
    // }
    // return "Other"

    console.log('navigator.userAgent', navigator.userAgent);
    var isiPhone = /iPad|iPhone|iPod/i.test(navigator.userAgent);
    var isMobile = /Mobile/i.test(navigator.userAgent);

    if (isiPhone && isMobile) {
        // Do something if the user is accessing from an iPhone
        return "iOS"
    } else {
        // Do something else if the user is not accessing from an iPhone
        return "Other"
    }
}

export const checkMarket = (ln, locale) => {
    let flag = false;
    ln.forEach(l => {
        if (l === locale.toLowerCase()) {
            flag = true
        }
    })

    return flag
} 