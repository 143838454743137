import React from 'react';
import { Input } from "antd";
import ValidationIcon from '../ValidationIcon';

const InputUI = ({id, label, required, classNames, ...props}) => {
  return (
    <>
      {label && (
        <div>
          <label className="form-label regular jw-label" htmlFor={id}>
            {label}&nbsp;{required && '*'}
          </label>
        </div>
      )}
      <div className="form-input-wrapper">
        <Input className={`form-input jw-label ${classNames}`} required={required} {...props} id={id} />
        <ValidationIcon />
      </div>
    </>
  );
};

export default InputUI;
