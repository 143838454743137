import React from "react";
import { Route } from "react-router-dom";

import EnableMicrophone from "./EnableMicrophone/EnableMicrophone"
import Record from "./Record/Record"
import Review from "./Review/Review"
import Error from "./Error/Error"

import "./record-message.scss"; 

import routes from "../../routes/routes";

export default function RecordMessage() { 
  return (
    <>
      <Route exact path={routes.record_message_enable_microphone} component={EnableMicrophone} />
      <Route exact path={routes.record_message_record} component={Record} />
      <Route exact path={routes.record_message_review} component={Review} />
      <Route exact path={routes.record_message_error} component={Error} />
    </>
  );
}