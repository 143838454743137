import React from 'react';
import { DatePicker } from "antd";
import ValidationIcon from '../ValidationIcon';

const DateUI = ({ id, label, required, classNames, ...props }) => {
  return (
    <>
      {label && (
        <div>
          <label className="form-label regular jw-label" htmlFor={id}>
            {label}&nbsp;{required && '*'}
          </label>
        </div>
      )}
      <div className="form-input-wrapper">
        <DatePicker className={`form-input jw-label ${classNames}`} required={required} {...props} id={id} />
        <ValidationIcon />
      </div>
    </>
  );
};

export default DateUI;
