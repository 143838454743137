import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import routes from "../../../routes/routes";

import JohnnieWalkerLogo from "../../../components/JohnnieWalkerLogo";

import backgroundImage from '../../../assets/images/backgrounds/your_details.png'
import './photo-picker.scss';

import { useStore } from '../../../contexts/app.context'
import ImageCropper from "../../../components/ImageCropper/ImageCropper";

export default function PhotoPicker() { 
  const { getPageContent, history, store, postPhoto, setCustomer, setPhoto, getMessage } = useStore()
  const [data, setData] = useState(null)

  useEffect(async () => {
    setData(getPageContent('add_a_photo'))
    window.scrollTo(0, 0);
  }, [])

  const onChange = async ({blob, base64, buffer}) => {
		if (buffer) {
			const { filename, has_profanity, Message } = await postPhoto(buffer)

			if (has_profanity === false) {
				setPhoto({blob, base64})
				setCustomer({profile_image: filename})
			}
	
			if (has_profanity === true) {
				history.push(routes.upload_photo_error);
				return
			}

      if (Message) {
        alert(Message)
        return
      }
		}
		
		// if all good: load the full message and go to preview page
		const fullUserMessage = await getMessage({message_token: store.customer.message_token})
    console.log(fullUserMessage);
    if (fullUserMessage.message_token) {
      setCustomer(fullUserMessage)
    }
		history.push(routes.message_preview)
  }

  const onError = async (error) => {
    console.warn('ImageCropper:', error)
    history.push(routes.upload_photo_error);
  }

  if (!data) return null

  return (
    <div className="photo-picker-page upload-photo-page page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />
          
          <div className="intro">
            <p className="jw-h6">{data.step5.text}</p>
            
            <h1 className="jw-h1" style={{ marginTop: '12px'}}>
              <div className="jw-h3" dangerouslySetInnerHTML={{ __html: data.title1.text }}></div>
              <div className="jw-h1" dangerouslySetInnerHTML={{ __html: data.title2.text }}></div>
            </h1>
          </div>

          <div className="body">
              <p className="jw-h6">{data.text1}</p>
              <div className="jw-h6" style={{ marginTop: '1em'}}>
                <p>{data.text2}</p>
                <div dangerouslySetInnerHTML={{__html: data.text3}}></div>
              </div>

              <ImageCropper 
                imageUrl={store.customer.profile_image || store.photo.base64}
                onChange={onChange}
                onError={onError}
                text1={data.text7}
                text2={data.text8}
                btnText={data.button1}
              />

              {/* skip this step link */}
              <Link to={routes.message_preview} className="jw-h6 text-center block" style={{ marginTop: '40px'}}>{data.text5}</Link>
          </div>
        </div>

      </div>
    </div>
  );
}