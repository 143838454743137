
import React, { useEffect, useState } from "react";
import { useStore } from '../../contexts/app.context'
import "./campaign-footer.scss";


const CampaignFooter = () => {
  const [data, setData] = useState(null)
  const { getPageContent } = useStore();

  useEffect(async () => {
    setData(getPageContent('campaign_footer'))
  }, [])

  if (!data) return null

  return (
  <>
    <div className="campaign-footer">
      <p>{data.text}</p>
      <ul>
        <li><a href={data.button1.url} target={data.button1.target}>{data.button1.text}</a></li>
        <li><a href={data.button2.url} target={data.button2.target}>{data.button2.text}</a></li>
        <li><a href={data.button3.url} target={data.button3.target}>{data.button3.text}</a></li>
        <li><a href={data.button4.url} target={data.button4.target}>{data.button4.text}</a></li>
      </ul>
    </div>
  </>
  )

}

export default CampaignFooter;
