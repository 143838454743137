import React from "react";
import { Form } from "antd";
import DateNativeUI from "../DateNativeUI";
import './DateField.scss';

export default function DateNativeField({ name, placeholder, rules, label, className, id, ...props }) {

  let patchedRules = rules ? [...rules] : []

  return (
    <Form.Item
      name={name}
      className={`form-item ${className}`}
      rules={patchedRules}
      {...props}
    >
      <DateNativeUI
        placeholder={placeholder}
        className="form-input jw-label"
        label={label}
        id={id || name}
      />
    </Form.Item>
  )
}