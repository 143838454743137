import React, { useEffect, useState } from "react";

import SubmitButton from "../../components/SubmitButton";
import { Form } from "antd";
import backgroundImage from '../../assets/images/backgrounds/you-re-entered-background.png'
import "./campaign-success.scss";
import CampaignFooter from '../../components/CampaignFooter';
import { useStore } from '../../contexts/app.context'


const CampaignPage = () => {
  const { getPageContent, history } = useStore()
  const [data, setData] = useState(null)

  useEffect(async () => {
    setData(getPageContent('campaign_success_page'))
  }, [])

  const onFinish = () => {
    history.push(data.button.url);
    return;
  };

  if (!data) return null

    return (
      <>
      <div style={{ backgroundImage: `url(${backgroundImage})`,backgroundPosition:'center'}}>
        <div className="page landing-page">
          <div className="content relative">
            <div className="inner">

              <div className="body center" style={{ marginTop: '50vh' }}>
                <h2 className="jw-h3">{data.title1.text}</h2>
                <h1 className="jw-h2">{data.title2.text}</h1>
                <p style={{ textAlign: 'center' }}>{data.text1}</p>
              </div>

              <Form onFinish={onFinish}>
                <SubmitButton
                  solid={false}
                  text={data.button.text}
                  style={{ marginTop: '30px' }}
                />
              </Form>
            </div>

          </div>
        </div>
      </div>
      <CampaignFooter />
      </>
  );
};

export default CampaignPage;
