import React from "react";

import { Form } from "antd";
import InputUI from "../InputUI";



const REGEX_ALPHA = new RegExp(/^[a-zA-Z\u00B5-\u00FF]|[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+$/);

const alphaValidator = (rule, value, message) => {
  if (REGEX_ALPHA.test(value) || value.length === 0) {
    return Promise.resolve();
  } else {
    return Promise.reject(message);
  }
};

export default function TextField({name, placeholder, rules, label, value, className, id, ...props}) {
  let patchedRules = rules ? [...rules] : []

  // add character only validator check
  if (rules) {
    patchedRules = [...rules, { validator: (rule, value) => alphaValidator(rule, value, rules[0].message) }]
  }

  return (
    <Form.Item
      name={name}
      rules={patchedRules}
      className={`form-item ${className}`}
      {...props}
    >
      <InputUI
        type="text"
        placeholder={placeholder}
        className="form-input jw-label"
        label={label}
        value={value}
        id={id || name}
      />
    </Form.Item>
  )
}