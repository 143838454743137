import React, { useEffect, useState } from "react";

import routes from "../../routes/routes";
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import ButtonUI from "../../components/ButtonUI";

import backgroundImage from '../../assets/images/backgrounds/your_details.png'

import './redeem-gift-code.scss'

import { useStore } from '../../contexts/app.context'

const LandingPage = () => { 
  const { getPageContent, history } = useStore()
  const [data, setData] = useState(null)
  
  useEffect(async () => {
    window.scrollTo(0, 0);
    setData(getPageContent('landing_page_errors'))
  }, [])

  const onTryAgain = async () => {
    history.replace(routes.enter_gift_code)
  }

  if (!data) return null

    return (
    <div className="page redeem-gift-code-page" style={{ backgroundImage: `url(${backgroundImage})`}}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} style={{marginBottom: '43px'}}/>
          
          <div className="intro">
            <h1 className="jw-h2">{data.title1.text}</h1>

          </div>

          <div className="body" style={{marginTop: '24px'}}>
            {/* your unique gifting experience */}
            <h2 className="jw-h3">
              <div>{data.title2.text}</div>
              <div>{data.title3.text} {data.yellow_title.text}</div>
              <div>{data.title4.text}</div>
            </h2>

            <ul className="requirements-ul" style={{marginTop: '24px'}}>
              <li className="jw-label jw-serif">{data.text1}</li>
              <li className="jw-label jw-serif">{data.text2}</li>
            </ul>

            <ButtonUI
              solid={true}
              text={data.button1 || 'Try Again'}
              style={{marginTop: '24px'}}
              onClick={onTryAgain}
            />
          </div>

        </div>

      </div>
    </div>
  );
};

export default LandingPage;
