import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import routes from "../../../routes/routes";

import JohnnieWalkerLogo from "../../../components/JohnnieWalkerLogo";
import ButtonUI from '../../../components/ButtonUI';

import backgroundImage from '../../../assets/images/backgrounds/your_details.png'

import { useStore } from '../../../contexts/app.context'

const EnableMicrophone = () => { 
  const { getPageContent, history } = useStore()
  const [data, setData] = useState(null)
  
  useEffect(async () => {
    setData(getPageContent('record_message'))
    window.scrollTo(0, 0);
  }, [])

  
  const enableMicrophone = async () => {
    history.push(routes.record_message_record);
  }

  if (!data) return null

  return (
    <div className="enable-microphone-page record-page page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />
          
          <div className="intro">
            <p className="jw-h6">{data.step4.text}</p>
            <h1 className="jw-h1" style={{ marginTop: '12px'}}>
              <span className="jw-h1">{data.title1.text}&nbsp;</span>
              <span className="jw-h3 inline-block">{data.title2.text}</span>
              <span className="jw-h2 text-right block" dangerouslySetInnerHTML={{ __html: data.title3.text }}></span>
            </h1>
          </div>

          {/* enable microphone */}
            <div className="body enable-microphone">
              <p className="jw-label jw-serif">{data.text1}</p>
              <ButtonUI
                solid={true}
                text={data.button1}
                onClick={enableMicrophone}
                id="enable_microphone_submit"
              />
                 
              <Link to={routes.type_message} className="text-center jw-label jw-serif block">{data.text2}</Link>
            </div>
        </div>

      </div>
    </div>
  );
};

export default EnableMicrophone;
