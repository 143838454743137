import React, { useEffect, useState } from "react";

import backgroundImage from '../../assets/images/backgrounds/triage-page-background.png'
import "./campaign-welcome.scss";
import CampaignFooter from '../../components/CampaignFooter';
import { useStore } from '../../contexts/app.context'


const CampaignWelcomePage = () => {
  const { getPageContent, history } = useStore()
  const [data, setData] = useState(null)

  useEffect(async () => {
    setData(getPageContent('campaign_welcome_page'))
  }, [])

  if (!data) return null

    return (
      <>
      <div className="out-wap" style={{ backgroundImage: `url(${backgroundImage})`,backgroundPosition:'center'}}>
        <div className="page landing-page">
          <div className="content relative">
            <div className="inner">

              <div className="btns" onClick={() => { history.push(data.button1.url) }}>
                <a>
                  <span className="jw-h3 yellow"> {data.button1.text} </span>
                  <span className="jw-h3 white">{data.text1}</span>
                </a>
              </div>

              <div className="btns" onClick={() => { history.push(data.button2.url) }}>
                <a>
                  <span className="jw-h3 yellow"> {data.button2.text} </span>
                  <span className="jw-h3 white">{data.text2}</span>
                </a>
              </div>

              <div className="btns" onClick={() => { history.push(data.button3.url) }}>
                <a>
                  <span className="jw-h3 yellow"> {data.button3.text} </span>
                  <span className="jw-h3 white">{data.text3}</span>
                </a>
              </div>

            </div>

          </div>
        </div>
      </div>
      <CampaignFooter />
      </>
  );
};

export default CampaignWelcomePage;
