import React, {useState} from "react"

import { Form, Select } from "antd"
const { Option } = Select

import InputUI from "../InputUI"
import './telephoneField.scss'

const CountryCodes = require('country-codes-list')
const countryCodes = CountryCodes.all()

const REGEX_DIGITS = new RegExp(/^\d+$/);

export default function TelephoneField({code_name, telephone_name, rules = [], placeholder, locale, label}) {
  let defaultCode = countryCodes.find((code)=> locale.toLowerCase() === `${code.officialLanguageCode}-${code.countryCode}`.toLowerCase());
  if(!defaultCode)
    defaultCode = countryCodes.find((code)=> locale.split('-')[1].toLowerCase() === code.countryCode.toLowerCase());

  const [, setCode] = useState(defaultCode)
  const [telephone, setTelelephone] = useState('')

  const onCodeChange = (value) => {
    const countryCode = countryCodes.find((code)=> code.countryCallingCode === value)
    setCode(countryCode)
  }

  const onTelephoneChange = (e) => {
    setTelelephone(e.target.value)
  }

  // add phone validator check
  const codeValidator = (rule, value) => {
    if (value) {
      return Promise.resolve();
    } else {
      return Promise.reject("error");
    }
  };
  
  const telephoneValidator = (rule, value) => {
    if (!value || REGEX_DIGITS.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(rules[0].message);
    }
  };

  const codeRules = [...rules, { validator: codeValidator }]
  const telephoneRules = [...rules, { validator: telephoneValidator }]

  return (
    <Form.Item
      className="telephone-field form-item"
    >
      <div>
        <label className="form-label jw-label" >
          {label}
        </label>
      </div>
      <div className="flex flex-row align-start">
        <Form.Item
          name={code_name}
          rules={codeRules}
          className="form-item country-code-field"
          initialValue={defaultCode.countryCallingCode}
        >
          <Select
            mode="single"
            className="select"
            showSearch
            onChange={onCodeChange}
            id={code_name}
            // search filter
            filterOption={(input, code) => 
              ('+' + code.country_calling_code + code.country_name_local + code.country_name_en + code.country_code).toLowerCase().includes(input.toLowerCase())
            }
          >
            {
            countryCodes.map((code)=> (
              <Option 
                value={code.countryCallingCode} 
                country_name_local={code.countryNameLocal} 
                country_name_en={code.countryNameEn} 
                country_calling_code={code.countryCallingCode} 
                country_code={code.countryCode} 
                key={code.countryCode}
               >
                <span style={{paddingRight: '7px', paddingTop: '2px', display: 'inline-block'}}>+</span>
                <span>{code.countryCallingCode}</span>
              </Option>
            )) 
            }
          </Select>
        </Form.Item>

        <Form.Item
          rules={telephoneRules}
          className="telephone-field form-item"
          name={telephone_name}
        >
          <InputUI
            type="tel"
            placeholder={placeholder}
            value={telephone}
            onChange={onTelephoneChange}
            className="form-input jw-label"
            id={telephone_name}
          />
        </Form.Item>
      </div>
    </Form.Item>
  )
}