import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import routes from "../../routes/routes";

import { Form } from "antd";
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import TextareaField from "../../components/Fields/TextareaField";
import SubmitButton from "../../components/SubmitButton";
import backgroundImage from '../../assets/images/backgrounds/your_details.png'

import { useStore } from '../../contexts/app.context'

const TypeMessage = () => {
  const { getPageContent, store, postTextMessage, setTextMessage, history } = useStore()
  const [data, setData] = useState(null)
  const [form] = Form.useForm();

  useEffect(async () => {
    form.setFieldsValue(store.customer)
    setData(getPageContent('type_message'))
    window.scrollTo(0, 0);
  }, [])

  const onFinish = async () => {

    const { text_message } = form.getFieldsValue()
    const { has_profanity, Message } = await postTextMessage(text_message)

    if (has_profanity === false) {
      await setTextMessage(text_message)
      history.push(routes.upload_photo)
    }
    if (has_profanity === true) {
      form.setFields([{ name: 'text_message', errors: ["Text has profanity and needs a nice error message"] }])
    }
    if (Message) {
      form.setFields([{ name: 'text_message', errors: [Message] }])
    }
  }

  if (!data) return null

  return (
    <div className="type-message-page page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />

          <div className="intro">
            <p className="jw-h6">{data.step4.text}</p>
            <h1 style={{ marginTop: '12px' }}>
              <span className="jw-h1">{data.title1.text}&nbsp;</span>
              <span className="jw-h3">{data.title2.text}</span>
              <span className="jw-h1 block text-right" dangerouslySetInnerHTML={{ __html: data.title3.text }}></span>
              
            </h1>
          </div>

          <div className="body message-recorder" style={{ marginTop: '16px' }}>
            <p className="jw-label jw-serif">{data.text1}</p>

            <Form
              className="form"
              form={form}
              onFinish={onFinish}
            >
              <TextareaField
                required
                label={data.text2}
                rows="5"
                name="text_message"
                maxLength={255}
                rules={[{ required: true ,message: data.text5 || '' }, {
                  validator: async (_, names) => {
                    if (names?.length >= 255) {
                      if(data.text4)
                      return Promise.reject(new Error(data.text4));
                    }
                  }
                }]}
              />

              <SubmitButton
                solid={true}
                text={data.button1}
                style={{ marginTop: '60px' }}
                id="typed_message_submit"
              />
            </Form>

            <Link to={routes.record_message} className="text-center jw-label jw-serif block" style={{ marginTop: '20px' }}>{data.text3}</Link>

          </div>
        </div>

      </div>
    </div>
  );
};

export default TypeMessage;
