import React, { useEffect, useState } from "react";

import routes from "../../routes/routes";

import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import backgroundImage from '../../assets/images/backgrounds/preview.jpg'
import playIcon from '../../assets/images/icons/play.svg'
import pauseIcon from '../../assets/images/icons/pause.svg'
import ButtonUI from "../../components/ButtonUI";
import DiscoverMoreImage from '../../assets/images/holding_glass.jpg'

import './message-preview.scss';

import { useStore } from '../../contexts/app.context'
import { EventTracking } from '../../utils/eventTracking';
import FeefbackForm from '../../custom/feedbackForm/feedbackForm';
import { checkfeedback, checkIsGermen, checkSubscriptions } from '../../utils/common'
import { Form } from "antd";
import EmailField from "../../components/Fields/EmailField";
import CheckboxField from "../../components/Fields/CheckboxField";
import SubmitButton from "../../components/SubmitButton";

let audio = null;

export default function MessagePreview() {
  const { getPageContent, store, postPublish, setPublish, clearState, history, submitSubscribeForm } = useStore()
  const [data, setData] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false);
  const [showFeedBack, setShowFeedBack] = useState(false);

  // subscribe Form
  const [form] = Form.useForm();
  const [formStatus, setFormStatus] = useState(undefined);
  const [subscribeSubmitButtonDisabled, setSubscribeSubmitButtonDisabled] = useState(false);


  // on load
  useEffect(async () => {
    if (store.customer.authoring_complete) {
      setData(getPageContent('message_preview'))
    } else {
      setData(getPageContent('preview'))
    }

    window.scrollTo(0, 0);
    if (store.customer.message_token) {
      if (store.customer.audio_file) {
        audio = new Audio(store.customer.audio_file);
        audio.onerror = (e) => {
          alert("Audio error: " + e.target.error.message)
        }
        audio.onplay = () => {
          setIsPlaying(true)
        }
        audio.onpause = () => {
          setIsPlaying(false)
        }
      }
    }
  }, [])

  // pause audio when unmounting
  useEffect(() => {
    return () => {
      if (audio) {
        audio.pause()
      }
    }
  }, [])

  const toggleAudio = async () => audio.paused ? audio.play() : audio.pause()

  const publishMessage = async () => {
    if (checkfeedback(store.customer.locale)) {
      setShowFeedBack(true)
    } else {
      const data = await postPublish() // posts the message token in the store
      OnPublishSuccess(data)
    }
  }

  const onFeedBackSuccess = async (rating) => {
    const data = await postPublish(rating) // posts the message token in the store
    OnPublishSuccess(data)
  }

  const OnPublishSuccess = (data) => {
    const { passcode, url, whatsAppUrl, Message, qrcode } = data // posts the message token in the store

    if (passcode) {
      EventTracking({
        'event': 'customEvent',
        'eventCategory': 'Submission',
        'eventAction': 'Submit Button Clicked',
        'eventLabel': 'Data Form Submit Button Clicked'
      });
      setPublish(passcode, whatsAppUrl, url, qrcode)
      history.push(routes.published);
    } else {
      alert(Message)
    }
  }

  const startAgain = async () => {
    if (store.customer.locale.toLowerCase() === 'ja-jp')
      history.push(routes.who_for);
    else {
      clearState();
      history.push(routes.landing);
    }
  }

  const submitForm = async () => {
    form.setFields([{ name: 'Error', errors: [] }])

    const formValues = form.getFieldsValue()

    console.log("Submitted Form");
    console.log("form values: ", formValues.email + ", " + formValues.accept_terms);

    const response = await submitSubscribeForm(formValues.email, formValues.accept_terms);
    if (response === true) {
      console.log("response was true")
      setSubscribeSubmitButtonDisabled(true);
      setFormStatus({ type: "success" })
    } else {
      console.log("response was false")
      setFormStatus({ type: "error" })
    }

  }

  console.log(data);
  if (!data) return null

  return (
    <div className="message-preview-page page" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'var(--content-width)' }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />

          <div className="body">
            <h1 className="jw-h1" style={{ marginTop: '70px' }}>{data.title1.text} {store.customer.recipient_name}</h1>
            {store.customer.locale.toLowerCase() !== 'ja-jp' ?
              <p className="jw-h1 text-right" style={{ marginTop: '280px' }} dangerouslySetInnerHTML={{ __html: store.customer.occassion }} /> :
              <p className="jw-h1 text-right" style={{ marginTop: '280px' }}></p>}

            {store.customer.profile_image &&
              <div className="profile-picture" style={{ marginTop: '144px' }}>
                {/*
                  because the aws image url stays the same for a user n matter if the user changes the image
                  the browser caches the url for the first image, so the image here does not change
                  i am hacking this by using the base64 from image upload instead
                */}
                <img src={store.photo.base64 || store.customer.profile_image} />
              </div>
            }

            <h2 className="jw-h3 text-center" style={{ marginTop: '24px' }}>{data.text1.text}</h2>

            {store.customer.audio_file &&
              <h2 className="jw-h3 text-center" style={{ marginTop: '21px', cursor: 'pointer' }} onClick={toggleAudio}>
                {isPlaying &&
                  <img src={pauseIcon} width="190" />
                }
                {!isPlaying &&
                  <img src={playIcon} width="190" />
                }
                {/* 
								<audio controls preload="auto" src={store.customer.audio_file} type="audio/ogg" >
									Your browser does not support the audio element.
								</audio>  */}
              </h2>
            }

            {store.customer.text_message && !store.customer.audio_file &&
              <h3 className="jw-h3" style={{ marginTop: '38px' }}>
                {store.customer.text_message}
              </h3>
            }

            <h2 className="" style={{ marginTop: '80px', paddingLeft: '70px' }}>
              <div className="jw-h3" dangerouslySetInnerHTML={{ __html: data.text2 }}></div>
              <div className="jw-h2">{store.customer.gifter_name}</div>
            </h2>
          </div>

          {!store.customer.authoring_complete &&
            <div className="action-buttons">
              <ButtonUI
                text={data.button1}
                solid={true}
                onClick={publishMessage}
                id="publish_message_submit"
              />
              <ButtonUI
                text={data.button2}
                solid={false}
                onClick={startAgain}
                id="start_again_submit"
              />
            </div>
          }


          {checkIsGermen(store.customer.locale) && data.form && store.customer.authoring_complete &&
            <div>
              <div className="discover-more">
                <p className="jw-h3 core-yellow" dangerouslySetInnerHTML={{ __html: data.title2.text }}></p>
                <img src={DiscoverMoreImage} />
                <a href="#" target="_blank" className="ant-btn ant-btn-primary button jw-h6 text-upper w-100 solid-fill cta core-yellow">
                  {data.text3}
                </a>
              </div>

              {!checkSubscriptions(store.customer.locale) && <div className="subscribe">
                <h2 className="jw-h3">{data.title3.text}</h2>
                <p>{data.text4}</p>

                <Form form={form} onFinish={submitForm}>
                  <EmailField
                    name="email"
                    label={data.form.email.lable}
                    placeholder={data.form.email.placeholder}
                    rules={[{ required: true, message: data.form.email.validationMessage }]}
                  />
                  <CheckboxField
                    name="accept_terms"
                    label={data.text4}
                    required={true}
                  />

                  <SubmitButton
                    disabled={subscribeSubmitButtonDisabled}
                    solid={true}
                    text={data.button}
                    id="subscribe_submit"
                    className={'w-100 button solid-fill primary-black'}
                  />

                  {formStatus?.type == "success" && <div className="response-msg success-msg">{data.formSuccess}</div>}
                  {formStatus?.type == "error" && <div className="response-msg error-msg"></div>}
                </Form>
              </div>}
            </div>
          }
        </div>

        {showFeedBack && <FeefbackForm onFeedBackSuccess={onFeedBackSuccess} />}

      </div>
    </div>
  );
}