import { AwsTranscribe } from "aws-transcribe";
import { downsampleBuffer } from "./helper";
import Timeout from "await-timeout";

const bufferSize = 8192;
const transcribeStreamSupport = ['ar-AE', 'zh-HK', 'en-US', 'zh-CN', 'fi-FI', 'pl-PL',' no-NO', 'pt-PT', 'th-TH', 'de-DE', 'it-IT', 'fr-FR', 'ko-KR', 'hi-IN', 'en-AU', 'pt-BR', 'sv-SE', 'ja-JP', 'ca-ES', 'es-US','fr-CA', 'en-GB']

const constraints = {
  audio: true,
  video: false,
};

const TheAudioContext =
  window.AudioContext || // Default
  window.webkitAudioContext || // Safari and old versions of Chrome
  false;

if (!navigator.mediaDevices) {
  if (!location.protocol.includes("https")) {
    alert("Microphone will not be accessible over non secure connection.");
  } else {
    console.warn(
      "Your browser doesn't have the support for media devices.",
      "Please upgrade or change the browser to access media devices"
    );
  }
} else {
  // computes the correct navigator
  navigator.mediaDevices.getUserMedia =
    navigator.mediaDevices.getUserMedia ||
    navigator.mediaDevices.webkitGetUserMedia ||
    navigator.mediaDevices.mozGetUserMedia;
}

class StreamingManager {
  constructor() {
    this.streaming = false;
    this.context = null;
    this.processor = null;
    this.input = null;
    this.globalStream = null;
    this.recognizedText = [];
    this.language = null;
  }

  init(language) {
    if (!language) {
      throw Error("you need to pass the language");
    }
    this.language = language;
    this.setupAudioResources();
  }

  setupAudioResources() {
    // create a context which will be used by stt and tts
    this.context = new TheAudioContext({
      latencyHint: "interactive",
    });
  }

  async startStreaming() {
    try {
      console.log("start streaming");
      this.recognizedText = [];

      await this.connectToTranscribe();

      if (!this.processor) {
        this.setupAudioProcessor();
      }

      if (this.context.state === "suspended") {
        this.context.resume();
      }

      // create a reference to the stream so we can close it after
      this.globalStream = await navigator.mediaDevices.getUserMedia(
        constraints
      );

      // setup an input from the stream and connect it to the processor
      this.input = this.context.createMediaStreamSource(this.globalStream);
      this.input.connect(this.processor);
    } catch (err) {
      console.log(err);
      // eslint-disable-next-line no-alert
      alert(
        "an error occured while starting the stream, view the console for errors"
      );
    }
  }

  async stopStreaming() {
    console.log("stop streaming");
    await Timeout.set(1500); // wait for the last stream info to be received.

    if (!this.globalStream) {
      console.log("no global stream");
      return;
    }

    // request to stop recognition
    this.globalStream.getTracks()[0].stop();
    this.globalStream = undefined;

    // input needs to be created from the global stream which may change so we can disconnect the last input
    this.input.disconnect(this.processor);
    this.input = undefined;

    // disconnect the processor so there's nothing happening in h
    this.processor.disconnect(this.context.destination);
    this.processor = undefined;

    // close the transcribe connection
    this.transcribeStream.removeAllListeners();
    this.transcribeStream.destroy();
    this.transcribeStream = undefined;
    this.client = undefined;

    return this.recognizedText.join();
  }
  setupAudioProcessor() {
    // setup the audio processor
    this.processor = this.context.createScriptProcessor(bufferSize, 1, 1);

    this.processor.onaudioprocess = (e) => {
      const buffer = e.inputBuffer.getChannelData(0);

      const pcmBuffer = downsampleBuffer(
        buffer,
        44100,
        this.transcribeConfig.sampleRate
      );

      this.sendAudioBuffer(pcmBuffer);
    };

    // connect the processor to the context
    this.processor.connect(this.context.destination);
  }

  sendAudioBuffer(chunk) {
    // eslint-disable-next-line no-underscore-dangle
    if (this.transcribeStream) {
      this.transcribeStream._write(chunk);
    }
  }

  async connectToTranscribe() {
    this.awsConfig = {
      accessKeyId: "AKIAR32VYYATBEF5FZWX",
      secretAccessKey: "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",
    };
    const findIndex = transcribeStreamSupport.findIndex(langCode=> langCode ===  this.language)

    this.transcribeConfig = {
      languageCode: findIndex < 0 ? 'en-GB' : this.language,
      region: "eu-west-1",
      sampleRate: 16000,
    };

    this.client = new AwsTranscribe(this.awsConfig);

    console.log("client created");

    console.log("aws transcribe config", this.transcribeConfig);
    this.transcribeStream = this.client.createStreamingClient(
      this.transcribeConfig
    );

    this.transcribeStream.on("open", () => {
      console.log("connected");
    });

    this.transcribeStream.on("data", (data) => {
      console.log("streaming");
      const results = data.Transcript.Results;

      if (!results || results.length === 0) {
        return;
      }

      const result = results[0];

      const final = !result.IsPartial;
      const text = result.Alternatives[0].Transcript;

      console.log("TEXT IS: ", text);

      if (final) {
        this.recognizedText.push(text);
      }
    });

    this.transcribeStream.on("error", (error) => {
      console.log("error occurred", error);
      this.stopStreaming();
      // eslint-disable-next-line no-alert
      alert("transcribe error occurred, view the console for details");
    });

    this.transcribeStream.on("close", (...args) => {
      console.log("closed", ...args);
    });
  }
}

const streamingManager = new StreamingManager();
export default streamingManager;
