import React, { useEffect } from "react";
import { injectScript } from "../../utils/inject-script";
import './footer.scss';

function Footer() {
  useEffect(() => {
    const injectScriptIfProduction = async () => {
      if (process.env.NODE_ENV === 'production') {
        if (!window.jQuery) {
          // jquery required for diageohorizon script
          await injectScript('https://code.jquery.com/jquery-3.6.0.min.js', { id: 'jqueryScript', forceInject: false });
          await injectScript('https://code.jquery.com/jquery-migrate-3.3.2.js', { id: 'jqueryMigrateScript', forceInject: false });
          await Promise.resolve(() => {
            if (window.jQuery) {
              const $j = window.jQuery;
              window.jQuery.fn.load = function () {$j(window).trigger("load", function() {
								console.log('loading')
							})};
              window.jQuery.fn.bind = function () {$j(window).trigger("on", function() {
								console.log('on')
							}); };
              window.jQuery.fn.unbind = function () {$j(window).trigger("off", function() {
								console.log('off')
							}); };
            }
          });
          await injectScript('//footer.diageohorizon.com/dfs/master.js', { id: 'horizonScript', forceInject: false });
        }
      }
    };
    injectScriptIfProduction();
  }, []);

  return (
    <footer className="site-footer">
      <div id="footer" className="horizon-footer"></div>
    </footer>
  );
}

export default Footer;
