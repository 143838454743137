export default function JWFetch(url, method, body, bearerToken) {
  //const apiUrl = process.env.NODE_ENV === 'production' ? 'https://secure-gifting-johnniewalker.diageoplatform.com/api/' : 'https://secure-blackmagic.thisissecure.net/api/'
  //const apiUrl = "https://secure-gifting-johnniewalker.diageoplatform.com/api/";
  //const apiUrl = "http://blackmagic-local.diageo.com/api/";
  // const apiUrl = 'https://jwgiftingadmin.transformcxdev.com/api/';
  // const apiUrl = 'https://secure-gifting-johnniewalker.diageoplatform.com/api/';
  const apiUrl = 'https://secure-gifting.johnniewalker.com/api/';
  // const apiUrl = process.env.NODE_ENV === 'production'
  //   ? 'https://secure-gifting.johnniewalker.com/api/'
  //   : 'https://secure-gifting-johnniewalker.diageoplatform.com/api/';

  let auth = bearerToken ? `Bearer ${bearerToken}` : "";
  //let apiKey = process.env.REACT_APIKEY;
  
  return fetch(`${apiUrl}${url}`, {
    method: method,
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "omit", // include, *same-origin, omit
    headers: {
      "x-api-key": process.env.REACT_APIKEY,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: auth,
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => res.json())
    .catch(async (error) => ({ Message: error.message }));
}
