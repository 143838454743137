import React, { useEffect, useState } from "react";

import routes from "../../routes/routes";

import { Form } from "antd";
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import SubmitButton from "../../components/SubmitButton";
import TextField from "../../components/Fields/TextField";
import backgroundImage from '../../assets/images/backgrounds/your_details.png'

import { useStore } from '../../contexts/app.context'
import ErrorField from "../../components/Fields/ErrorField";
import { checkMarket } from '../../utils/common';
import BeIntro from "./be-intro";
import Intro from "./intro";

const WhoFor = () => {
  const { getPageContent, store, history, postWhoFor, setWhoFor } = useStore()
  const [data, setData] = useState(null)
  const [form] = Form.useForm();

  useEffect(async () => {
    window.scrollTo(0, 0);
    form.setFieldsValue(store.customer)
    setData(getPageContent('who_for'))
  }, [])

  const onFinish = async () => {
    form.setFields([{ name: 'Error', errors: [] }])

    const formData = form.getFieldsValue()
    const { recipient_name, gifter_name, Message } = await postWhoFor(formData.recipient_name, formData.gifter_name)
    if (gifter_name) {
      setWhoFor(recipient_name, gifter_name)
      history.push(routes.occasion);
    }
    if (Message) {
      form.setFields([{ name: 'Error', errors: [Message] }])
    }
  };


  if (!data) return null

  return (
    <div className="who-for-page page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />
          {
            checkMarket(['fr-be', 'nl-be'], store.customer.locale) ? <BeIntro data={data} /> : <Intro data={data} />
          }
          <div className="body" style={{ marginTop: '40px' }}>
            <Form form={form} onFinish={onFinish}>
              <TextField
                name="recipient_name"
                label={data.form.for.lable}
                placeholder={data.form.for.placeholder}
                rules={[{ required: true, message: data.form.for.validationMessage }]}
              />
              <TextField
                name="gifter_name"
                label={data.form.from.lable}
                placeholder={data.form.from.placeholder}
                rules={[{ required: true, message: data.form.from.validationMessage }]}
              />

              <ErrorField name="Error" />

              <SubmitButton
                solid={false}
                text={data.button}
                style={{ marginTop: '221px' }}
                id="who_for_submit"
              />
            </Form>
          </div>

        </div>

      </div>
    </div>
  );
};

export default WhoFor;
