import React, { useEffect, useState } from "react";

import routes from "../../routes/routes";
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import SubmitButton from "../../components/SubmitButton";

import backgroundImage from '../../assets/images/backgrounds/your_details.png'

import { useStore } from '../../contexts/app.context'

export default function MessageDeletedFailed(){
  const { getPageContent, history } = useStore()
  const [data, setData] = useState(null)

  useEffect(async () => {
    window.scrollTo(0, 0);
    setData(getPageContent('message_delete_error_page'))
  }, [])

	console.log(data);

  if (!data) return null

    return (
    <div className="page redeem-gift-code-page" style={{ backgroundImage: `url(${backgroundImage})`}}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />
          
          <div className="intro">
            {/* to the ones that keep walking */}
            <h1 className="block jw-h2">{data.title1.text}</h1>
          </div>

          <div className="body" style={{marginTop: '32px'}}>
						<h2 className="block jw-h4">{data.title2.text}</h2>

            {/* your unique gifting experience */}
						<div style={{marginTop: '30px'}}>
							<SubmitButton onClick={() => history.push(routes.landing)} text={data.button1} type="button"  id="message_delete_failed_home_submit"/>
						</div>
          </div>

        </div>

      </div>
    </div>
  );
}