import React from "react";
import SpinnerGif from '../../assets/images/spinner.gif';
import './index.scss';

export default function Loader({show}) {
  if (!show) return null;

  return (
    <div className="loader-container w-100 flex flex-column align-center">
      <div className="loader-wrap">
        <img src={SpinnerGif} alt="Loading" className="w-100" style={{borderRadius: '50%', 'padding': '8px', 'background': '#fcb913'}} />
      </div>
    </div>
  )
}