import React from "react";
import { Form } from "antd";
import { Checkbox } from 'antd';
import './checkboxField.scss'

export default function CheckboxField({name, label, required, id, ...props}) {
  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      rules={
        [{
          required: required || false,
          transform: value => (value || null),  // Those two lines
          type: 'boolean',                           // Do the magic
          message: '',
        }]
      }
      className="checkbox-field form-item"
      {...props}
    >
      <Checkbox id={id || name}><span className="checkbox-field-text jw-sm italic" dangerouslySetInnerHTML={{__html: label}}></span></Checkbox>
    </Form.Item>
  )
}