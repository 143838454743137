import React from 'react';
import { Modal } from 'antd';
import './modelPopup.scss'

const ModelPopup = ({ title, visible, handleOk, handleCancel, children,...props }) => {

    return (
        <Modal title={title} open={visible} onOk={handleOk} onCancel={handleCancel}{ ...props} className={'model-popup ' + props.className}>
            {children}
        </Modal>
    )

}

export default ModelPopup