import React, { useEffect, useState } from "react";

import routes from "../../routes/routes";
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import SubmitButton from "../../components/SubmitButton";

import { Form } from "antd";
import backgroundImage from '../../assets/images/backgrounds/your_details.png'
import KeepWalkingSvg from '../../assets/images/brand/keep-walking.svg'

import './redeem-gift-code.scss'

import { useStore } from '../../contexts/app.context'
import TextField from "../../components/Fields/TextField";
import ErrorField from "../../components/Fields/ErrorField";

const LandingPage = () => { 
  const { getPageContent, history, getMessage, setMessage } = useStore()
  const [data, setData] = useState(null)
  const [form] = Form.useForm();
  const code = new URLSearchParams(window.location.search).get('passcode') ;

  useEffect(async () => {
    window.scrollTo(0, 0);
    setData(getPageContent('giftee_landing_page'))
    form.setFieldsValue({'gift_code': code})
  }, [])

  const onFieldsChange = (e) => {
    if (e.target.value.length === 5) {
      form.submit()
    }
  };

  const onFinish = async () => {
    form.setFields([{name: 'Error', errors: []}])
    const { gift_code } = form.getFieldsValue()

    const message = await getMessage({gift_code})

    if (message.has_expired === false) {
      setMessage(message)
      history.push(routes.message_preview);
    }

    if (message.Message || message.has_expired) {
      history.push(routes.enter_gift_code_error);
    }

    return;
  };

  if (!data) return null

    return (
    <div className="page redeem-gift-code-page" style={{ backgroundImage: `url(${backgroundImage})`}}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />
          
          <div className="intro">
            {/* to the ones that keep walking */}
            <h1>
              <span className="block  jw-h1">{data.title1.text}</span>
              <span className="block  jw-h1 text-right">{data.title2.text}</span>
            </h1>
            {/* keep walking rectangle */}
            <img alt="keep walking" src={KeepWalkingSvg} />
          </div>

          <div className="body" style={{marginTop: '32px'}}>
            {/* your unique gifting experience */}
            <h2 className="jw-h3">
              <div>{data.text1}</div>
              <div>{data.text2}</div>
            </h2>


            <Form form={form} onFinish={onFinish} style={{marginTop: '16px'}}>
              <TextField 
                name="gift_code"
                placeholder="- - - - -" 
                label={data.text3}
                autoComplete="off" 
                className="gift-code-field" 
                maxLength="5" 
                autoCorrect="off" 
                autoCapitalize="none" 
                onChange={onFieldsChange}
              />

              <ErrorField name="Error" />

              <SubmitButton
                solid={true}
                text={data.button1}
                style={{marginTop: '16px'}}
              />
            </Form>
          </div>

        </div>

      </div>
    </div>
  );
};

export default LandingPage;
