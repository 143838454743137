import React from "react";

import { Form } from "antd";
import { Select } from 'antd';
export const { Option } = Select;

import './selectField.scss';

export default function SelectField({name, placeholder, rules, label, value, id, children, onChange}) {
  return (
    <Form.Item
      name={name}
      rules={rules}
      className="form-item select-field"
      label={label}
    >
      <Select onChange={onChange} id={id || name} defaultValue={value} placeholder={placeholder} style={{ width: '100%' }}>
        {children}
      </Select>
    </Form.Item>
  )
}
    