import React, { useState, useEffect, useRef } from 'react'
import { Form } from "antd";
import ModelPopup from '../../components/ModelPopup/ModelPopup';
import SubmitButton from '../../components/SubmitButton'
import StarIcon from '../../components/Icons/StarIcon';
import CloseIcon from '../../components/Icons/CloseIcon';
import { useStore } from '../../contexts/app.context';
import CheckboxField from "../../components/Fields/CheckboxField";
import './feedbackForm.scss';

const FeefbackForm = ({ onFeedBackSuccess }) => {

    const [showModel, setShowModel] = useState(true);
    const [selectedStars, setSelectedStars] = useState(0);
    const items = Array(5).fill(null);
    const [data, setData] = useState(null)
    const { getPageContent } = useStore()
    const [form] = Form.useForm();
    const messageRef = useRef(false);
    let timer;

    useEffect(() => {
        setData(getPageContent('ratings'));
        setTimeout(() => {
            if (!messageRef.current)
                timer = selectedStars ? onFeedBackSuccess(selectedStars) : onFeedBackSuccess();
        }, 30000);
    }, []);

    const handleCancel = () => {
        setShowModel(false)
        onFeedBackSuccess();
    }

    const onStarClicked = (index) => {
        setSelectedStars(index)
    }

    const onFinish = async () => {
        messageRef.current = true;
        clearTimeout(timer);
        form.setFields([{ name: 'Error', errors: [] }])
        const formValues = form.getFieldsValue()
        if (formValues?.accept_terms) {
            onFeedBackSuccess(selectedStars)
            setShowModel(false)
        }
    }

    const drawFooter = () => {
        return (
            <Form className="form" form={form} onFinish={onFinish}>
                <CheckboxField
                    name="accept_terms"
                    label={data.description3}
                    required={true}
                />
                <SubmitButton text={data.button1} type="button" className={'w-100 button solid-fill primary-black'}></SubmitButton>
            </Form>);
    }

    return (
        <>
            {data && <ModelPopup visible={showModel} handleCancel={handleCancel} footer={drawFooter()} className='feed-back-wrapper' width={768} closeIcon={<CloseIcon width="20" />} maskClosable={false}>
                <h2 className='jw-h2 title text-center'>{data.title1.text}</h2>
                <div className='jw-label jw-serif italic text-center feedback-description'>
                    {data.description1}
                </div>
                <div className='jw-label jw-serif italic text-center feedback-notes' dangerouslySetInnerHTML={{ __html: data.description2 }} />
                <div className='star-wrapper'>{
                    items.map((item, index) => <StarIcon key={index} width="30" selected={index <= selectedStars - 1 ? true : false} onClick={() => onStarClicked(index + 1)} />)
                }
                </div>
            </ModelPopup>
            }
        </>
    )
}

export default FeefbackForm
