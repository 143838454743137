import React from 'react';
import { Button } from 'antd';

/*
Props: {
  text
  link
  sticky
  solid
  htmlType
  className
  disabled
}
*/

const ButtonUI = ({
  text,
  link,
  sticky = false,
  solid = false,
  htmlType = 'submit',
  classNames = 'primary-black',
  ...rest
}) => {
  return (
    <div className={`button-ui ${sticky ? 'stick-to-btm stick-left' : ''}`}>
      <Button
        type="primary"
        htmlType={htmlType}
        className={`button jw-h6 flex flex-column flex-center justify-center text-upper w-100 ${link ? 'is-link' : ''} ${solid && 'solid-fill'} ${classNames}`}
        {...rest}
      >
        {text}
      </Button>
    </div>
  );
};

export default ButtonUI;
