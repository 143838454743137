
export const injectScript = (
    url,
    options = {},
  ) => new Promise((resolve, reject) => {
    const { id, forceInject } = options;
    if (!id) {
      reject(new Error('Options must contain an id'));
    }
    let existingScript = document.getElementById(id);
  
    if (existingScript) {
      if (!forceInject) {
        resolve();
      } else {
        existingScript.remove();
        existingScript = null;
      }
    }
    if (!existingScript) {
      const script = document.createElement('script');
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject(new Error('Script Loading failed'));
      };
      script.src = url;
      script.id = id;
      script.async = true;
      document.body.appendChild(script);
    }
  });
  