import React from "react";

import { Form } from "antd";
import InputUI from "../InputUI";

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function EmailField({name, rules, placeholder, label, id}) {
  let patchedRules = [...rules]

  // add email validator check
  if (rules) {
    const emailValidator = (rule, value) => {
      if (REGEX_EMAIL.test(value) || value.length === 0) {
        return Promise.resolve();
      } else {
        return Promise.reject(rules[0].message);
      }
    };
    patchedRules = [...rules, { validator: emailValidator }]
  }

  return (
    <Form.Item
      name={name}
      rules={patchedRules}
      className="form-item"
    >
      <InputUI
        type="text"
        placeholder={placeholder}
        className="form-input jw-label"
        label={label}
        onChange={() => {}}
        id={id || name}
      />
    </Form.Item>
  )
}