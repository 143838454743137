import React, { useEffect, useState } from "react"

import { useStore } from '../../../contexts/app.context'

import routes from "../../../routes/routes"
import { Link } from "react-router-dom"

import JohnnieWalkerLogo from "../../../components/JohnnieWalkerLogo"
import ButtonUI from '../../../components/ButtonUI';
import backgroundImage from '../../../assets/images/backgrounds/your_details.png'
import PlayIcon from "../../../components/Icons/PlayIcon"
import PauseIcon from "../../../components/Icons/PauseIcon"

import './review.scss';


export default function EnableMicrophone() { 
  const { store, getPageContent, history} = useStore()

  if (!store.recording.file) {
    history.push(routes.record_message_enable_microphone)
    return null
  }

  const [data, setData] = useState(null)
  const [sliderValue, setSliderValue] = useState(0)
  const [recordingMinuteSeconds, setRecordingMinuteSeconds] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [player] = useState(new Audio(URL.createObjectURL(store.recording.file)))

  // mount
  useEffect(async () => {
    if (store.recording.file) {
      window.scrollTo(0, 0);
      setData(getPageContent('record_message'))
    
      // update slider knob as audio plays
      player.ontimeupdate = function(e) { 
        setSliderValue(e.target.currentTime);

        if (!recordingMinuteSeconds) {
          setRecordingMinuteSeconds(()=> {
            const time = player.duration
            const minutes = Math.floor(time/60)
            const seconds = (minutes < 1 ? time : time - (60*minutes)).toFixed(0)
            return `${minutes}:${seconds < 10 ? '0'+seconds : seconds}`
          })
        }
      }
      player.onplay = () => {
        setIsPlaying(true)
      }
      player.onpause = () => {
        setIsPlaying(false)
      }
    }
  }, [])

  // unmount
  useEffect(() => {
    return ()=> {
      player.pause()
    }
  }, [])

  // methods
  const toggleAudio = () => {
    if (player.paused) {
      player.play()
    } else {
      player.pause()
    }
  }

  const onSliderChange = (e) => {
    const value = parseFloat(e.target.value)
    player.currentTime = value;
    setSliderValue(value)
  }

  const onUseMessage = async () => {
    history.push(routes.upload_photo);
  }

  // guards
  if (!data) return null

  return (
    <div className="review-record-page record-page page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />
          
          <div className="intro">
            <p className="jw-h6">{data.step4.text}</p>
            <h1 className="jw-h1" style={{ marginTop: '12px'}}>
              <span className="jw-h1">{data.title1.text}&nbsp;</span>
              <span className="jw-h3">{data.title2.text}</span>
              <span className="jw-h2 text-right block" dangerouslySetInnerHTML={{ __html: data.title3.text }}></span>
            </h1>
          </div>

          <div className="body">
            <p className="jw-label jw-serif">{data.description1}</p>
            <p className="jw-label jw-serif" style={{marginTop: '16px'}}>{data.text4}</p>
            {
              store.recording.file && (
                <div className="audio-player" >
                  <button className="audio-player-button" onClick={toggleAudio} style={{background: 'transparent', border: '0', padding: 0, marginTop: '12px'}}>
                  {
                    !isPlaying ? <PlayIcon width="87" /> : <PauseIcon width="87" />
                  }
                  </button>
                  {/* <audio src={store.recording.file}></audio> */}
                  <div className="audio-player-slider">
                    <input type="range" value={sliderValue} onChange={onSliderChange} min="0" max={player.duration || 3} step="0.1" />
                  </div>
                  <div className="audio-player-duration">{recordingMinuteSeconds}</div>
                </div>
              )
            }
            {/* use this message */}
            <ButtonUI
              solid={true}
              text={data.button3}
              onClick={onUseMessage}
              id="review_submit"
            />
            {/* record again */}
            <Link to={routes.record_message_record} className="text-center jw-label jw-serif block">{data.text5}</Link>
            <Link to={routes.type_message} className="text-center jw-label jw-serif block">{data.text6}</Link>
          </div>
        </div>

      </div>
    </div>
  );
}