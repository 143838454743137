import React, { useEffect, useState } from "react"
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo"
import backgroundImage from '../../assets/images/backgrounds/your_details.jpg'
import DiscoverMoreImage from '../../assets/images/holding_glass.jpg'
import { checkIsGermen} from '../../utils/common'

import './message-published.scss'

import { useStore } from '../../contexts/app.context'

export default function MessagePreview() {
  const { getPageContent, store } = useStore()
  const [data, setData] = useState(null)

  useEffect(async () => {
    setData(getPageContent('confirmation'))
    window.scrollTo(0, 0);
  }, [])

  if (!data) return null

  return (
    <div className="message-published-page page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />

          <div className="intro" style={{ marginTop: '42px' }}>
            <h1>
              <div className="jw-h2">{data.title1.text}</div>
              <div className="jw-h2" dangerouslySetInnerHTML={{ __html: data.title2.text }}></div>
              <div className="flex flex-column align-end">
                <div>
                  <div className="jw-h2">{data.title3.text}</div>
                  <div className="jw-h2" dangerouslySetInnerHTML={{ __html: data.title4.text }} ></div>
                </div>
              </div>
            </h1>
          </div>

          <div className="body">
            {/* short link */}
            <div>
              <p className="jw-p jw-serif" style={{ marginTop: '52px' }} dangerouslySetInnerHTML={{ __html: data.text1.replace('{To}', store.customer.recipient_name) }}></p>
              {/* {!checkIsGermen(store.customer.locale) &&
                store.customer.qrcode && <a className="jw-p jw-serif" rel="noreferrer" target={'_blank'} href={store.customer.url}><u><b>{store.customer.url}</b></u></a>} */}
            </div>

            {/* whatsapp link */}
            {/* <div className="hidden-desktop">
              <p className="jw-p jw-serif" style={{ marginTop: '30px' }} dangerouslySetInnerHTML={{ __html: data.title6 }}></p>
              {!showWhatupUrlMarket(store.customer.locale) && <a className="jw-p jw-serif" href={store.customer.whatsAppUrl}><u><b>{
                !checkIsGermen(store.customer.locale) ? store.customer.whatsAppUrl : data.whatsapptext || store.customer.whatsAppUrl}</b></u></a>}
            </div> */}
            <p className="jw-p jw-serif" dangerouslySetInnerHTML={{ __html: data.text2 }} style={{ marginTop: '28px' }}></p>
            {store.customer.locale.toLowerCase() === 'ja-jp' && store.customer.qrcode
              &&
              <div className="qr-code">
                <img src={store.customer.qrcode} />
              </div>
            }
            <p className="jw-p jw-serif" dangerouslySetInnerHTML={{ __html: data.text3 }} style={{ marginTop: '28px' }}></p>
            
            {!checkIsGermen(store.customer.locale) && <p className="jw-h5 jw-serif text-center" style={{ marginTop: '16px', letterSpacing: '4px' }}><b>{store.customer.passcode}</b></p>}
   
            <p className="jw-p jw-serif" dangerouslySetInnerHTML={{ __html: data.text4 }} style={{ marginTop: '28px' }}></p>
          </div>

          <div className="discover-more">
            <p className="jw-h3 core-yellow" dangerouslySetInnerHTML={{ __html: data.title5 }}></p>
            <img src={DiscoverMoreImage} />
            <a href={data.button.url} target={data.button.target} className="ant-btn ant-btn-primary button jw-h6 text-upper w-100 solid-fill cta core-yellow">{data.button.text}</a>
          </div>
        </div>
      </div>
    </div>
  );
}