import React, { useState, useEffect } from 'react'
import { Form } from "antd";
import ModelPopup from '../components/ModelPopup/ModelPopup';
import SelectField, { Option } from "../components/Fields/SelectField";
import SubmitButton from '../components/SubmitButton'
import { useStore } from '../contexts/app.context';
import './checkLocaleForBe.scss';

const CheckLocaleForBE = () => {
    const { state, getPageContent } = useStore();
    const [showModel, setShowModel] = useState(false);
    const [data, setData] = useState(null)
    const [model, setModel] = useState({ selected_locale: 'nl-BE' });
    const [form] = Form.useForm();
    const locales = [{ key: 'nl-BE', value: 'Belgium (nl)' }, { key: 'fr-BE', value: 'Belgium (fr)' }]

    useEffect(() => {
        if (state.customer.locale?.toLowerCase() === 'nl-be' || state.customer.locale?.toLowerCase() === 'fr-be') {
            setData(getPageContent('landing_page'))
            const be_locale = sessionStorage.getItem('be_locale')
            if (!be_locale) {           
                const locale_from_storage = state.customer.locale || localStorage.getItem('JMF_LOCALE')
                if (locale_from_storage) {
                    setModel({ selected_locale: locale_from_storage });
                } else {
                    setModel({ selected_locale: 'nl-BE' });
                }
                setShowModel(true);
            } else if (state.customer.locale?.toLowerCase() !== be_locale.toLowerCase())
                window.location.pathname = be_locale;
        }
    }, [state.customer.locale])


    const handleOk = () => {
        const { selected_locale } = form.getFieldsValue();
        if (selected_locale) {
            setModel({ selected_locale });
            sessionStorage.setItem('be_locale', selected_locale)
            window.location.pathname = selected_locale;
        }
        setShowModel(false)
    }

    const handleCancel = () => {
        setShowModel(false)
    }

    const drawFooter = () => {
        return (<SubmitButton onClick={() => handleOk()} text={data?.button1} type="button" className={'w-100 button solid-fill primary-black check-locale-be-submit-button'}></SubmitButton>)
    }

    return (<>
        <ModelPopup visible={showModel} handleOk={handleOk} handleCancel={handleCancel} footer={drawFooter()}>
            <Form form={form} initialValues={model} className={'check-locale-be-wrapper'} >
                <SelectField name="selected_locale">
                    {locales.map((lang) => (
                        <Option key={lang.key} value={lang.key}>{lang.value}</Option>
                    ))}
                </SelectField>
            </Form>

        </ModelPopup>
    </>

    )
}

export default CheckLocaleForBE;