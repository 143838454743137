import React from "react";
import { Route } from "react-router-dom";

import PhotoPicker from "./PhotoPicker/PhotoPicker"
import PhotoPickerError from "./Error/Error"

import "./upload-photo.scss"; 

import routes from "../../routes/routes";

const UploadPhoto = () => { 
  return (
    <>
      <Route exact path={routes.upload_photo} component={PhotoPicker} />
      <Route exact path={routes.upload_photo_error} component={PhotoPickerError} />
    </>
  );
};

export default UploadPhoto;
