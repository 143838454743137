import React, { useEffect, useState } from "react";

import routes from "../../routes/routes";

import { Form } from "antd";
import JohnnieWalkerLogo from "../../components/JohnnieWalkerLogo";
import SelectField, { Option } from "../../components/Fields/SelectField";
import TextField from "../../components/Fields/TextField";
import SubmitButton from "../../components/SubmitButton";
import backgroundImage from '../../assets/images/backgrounds/your_details.png'

import { useStore } from '../../contexts/app.context'
import ErrorField from "../../components/Fields/ErrorField";

const Occasion = () => {

  // Added for Germany and The Netherlands so that then can add a custom input for the Ocassion name.
  const germanyOtherOptionId = "ffa514cc-d9b4-483d-93e4-c5980a2b639b";
  const netherlandsOtherOptionId = "49d858f3-05da-4285-b32c-3edd8fc64a8d";
  const franceBelgiumOtherOptionId = "e74396b1-2f1a-4b39-b6ff-2ae42a9b07a3";
  const netherlandBelgiumOtherOptionId = "4b2bcf58-ba4c-4ccc-86d8-38b30a261709";
  const [useCustomOcassion, setUseCustomOcassion] = useState(false);

  const { getPageContent, store, history, occasions, postOccasion, setOccasion } = useStore()
  const [data, setData] = useState(null)
  const [form] = Form.useForm();

  useEffect(async () => {
    window.scrollTo(0, 0);
    if (store.customer.occassion_id) {
      form.setFieldsValue({ occassion_id: store.customer.occassion_id })
    }

    setData(getPageContent('occasion'))
  }, [])

  const onFinish = async () => {
    form.setFields([{ name: 'Error', errors: [] }])

    const { occassion_id, other_ocassion } = form.getFieldsValue()
    const { occassion, Message } = await postOccasion(occassion_id, other_ocassion)
    console.log("POST Success and Occasion is: ", occassion);

    if (occassion_id) {
      setOccasion(occassion_id, occassion, other_ocassion)
      history.push(routes.record_message);
    }
    if (Message) {
      form.setFields([{ name: 'Error', errors: [Message] }])
    }
  };

  function onOcassionDropdownChange(value) {
    console.log("Dropdown Value Selected is: ", value);
    if (value === germanyOtherOptionId || value === netherlandsOtherOptionId || value === franceBelgiumOtherOptionId || value === netherlandBelgiumOtherOptionId) {
      setUseCustomOcassion(true);
    } else
      setUseCustomOcassion(false);
  }


  if (!data) return null

  return (
    <div className="occasion-page page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content relative">
        <div className="inner">
          <JohnnieWalkerLogo black={true} />

          <div className="intro">
            <p className="jw-h6">{data.step3.text}</p>
            <h1 style={{ marginTop: '12px' }}>
              <span className="jw-h1">{data.title1.text}&nbsp;</span>
              <span className="jw-h3">{data.title2.text}</span>
              <span className="jw-h1 block text-right">{data.title3.text}</span>
            </h1>
          </div>

          <div className="body" style={{ marginTop: '65px' }}>
            <Form form={form} onFinish={onFinish}>
              <SelectField
                name="occassion_id"
                onChange={onOcassionDropdownChange}
                placeholder={data.dropdown}
                rules={[{ required: true, message: "Required" }]}
              >
                {occasions.map((occasion) => (
                  <Option key={occasion.key} value={occasion.key}>{occasion.value}</Option>
                ))}
              </SelectField>


              {useCustomOcassion ? <TextField name="other_ocassion" placeholder={data.typeocassion}></TextField> : null}


              <ErrorField name="Error" />

              <SubmitButton
                solid={false}
                text={data.button}
                style={{ marginTop: '221px' }}
                id="occasion_submit"
              />
            </Form>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Occasion;
