import React from "react";
import { Form } from "antd";

import './errorField.scss';

export default function ErrorField({name}) {
  return (
    <Form.Item
      name={name || 'Error'}
      className="form-item error-field"
    >
      <input hidden/>
    </Form.Item>
  )
}