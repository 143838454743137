import React from "react";

import { Form } from "antd";
import { Input } from 'antd';
const { TextArea } = Input;

export default function TextareaField({name, placeholder, rows, rules, label, required, value, id, ...rest}) {
  let patchedRules = [{
    required: required || false,
    transform: value => (value || null),
    message: '',
  }]
  
  if (rules) {
    patchedRules = [...patchedRules, ...rules ]
  }

  return (
    <Form.Item
      id={id || name}
      name={name}
      rules={patchedRules}
      className="form-item"
      label={label}
    >
      <TextArea 
        rows={rows}
        placeholder={placeholder}
        className="form-input form-textarea jw-label"
        value={value}
        {...rest}
      />
    </Form.Item>
  )
}